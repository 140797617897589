import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  // {
  //   path: '',
  //   loadChildren: () => import('./../features/login-process/login-process.module').then(m => m.LoginProcessModule)
  // },
  {
    path: '',
    loadChildren: () => import('./../features/landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./../admin/admin-landing/admin-landing.module').then(m => m.AdminLandingModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'tpm-mobile',
    // loadChildren: () => import('./../features/landing-page/landing-page.module').then(m => m.LandingPageModule),
    children: [
      {
        path: '',
        loadChildren: () => import('./../features/landing-page/landing-page.module').then(m => m.LandingPageModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'center-line',
        loadChildren: () => import('./../features/center-line/center-line.module').then(m => m.CenterLineModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'cil',
        loadChildren: () => import('./../features/cil-tasks/cil-tasks.module').then(m => m.CilTasksModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'defect',
        loadChildren: () => import('./../features/defect/defect.module').then(m => m.DefectModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'dlm',
        loadChildren: () => import('./../features/dlm/dlm.module').then(m => m.DlmModule),
        canActivate: [MsalGuard]
      },
      {
        path: 'opl',
        loadChildren: () => import('./../features/opl/opl.module').then(m => m.OplModule),
        canActivate: [MsalGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeaturesRoutingModule { }
