import { Injectable } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  closeResult = '';

  constructor(private modalService: NgbModal){}

  open(content: any, isLarge:boolean = false) {
    if(isLarge){
      this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop : 'static',
        keyboard : false,
        size: 'xlg'
      }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }else{
      this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        backdrop : 'static',
        keyboard : false
      }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }

  }

  openSm(content: any) {
    console.log(content);
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', backdrop : 'static',
    keyboard : false, size: 'sm' }).result.then((result) => {
      console.log(result);
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openLg(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', backdrop: 'static',
      keyboard: false, size: 'lg'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}

