import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelect2Module } from 'ng-select2';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { ConfirmDialogComponent } from './component/confirm-dialog/confirm-dialog.component';
import { DataService } from './services/data.service';
import { LoaderService } from './services/loader.service';
import { WindowService } from './services/window.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { ModalService } from './services/modal.service';
import { MainHeaderComponent } from './component/common/main-header/main-header.component';

import { MainFooterComponent } from './component/common/main-footer/main-footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { BnNgIdleService } from 'bn-ng-idle';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DefectCardModalComponent } from './component/common/defect-card-modal/defect-card-modal.component';
import { AlphabetsOnlyDirective } from './directives/alphabets-only.directive';
import { OnlyNumberWithoutDecimalDirective } from './directives/only-number-without-decimal.directive';
import { CdkDropListActualContainerDirective } from './directives/cdk-drop-list-actual-container.directive';
import { FilterPipe } from './pipes/filter.pipe';
import { LazyloadDirective } from './directives/lazyload.directive';

// required for AOT compilation
export function sharedHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [ConfirmDialogComponent, MainHeaderComponent, OnlyNumberDirective, MainFooterComponent, DefectCardModalComponent, SpinnerComponent, AlphabetsOnlyDirective, OnlyNumberWithoutDecimalDirective, CdkDropListActualContainerDirective, FilterPipe, LazyloadDirective],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: sharedHttpLoaderFactory,
          deps: [HttpClient],
      },
      isolate: true
    }),
    NgbDropdownModule,
    NgSelect2Module,
    NgbModule,
    NgMultiSelectDropDownModule,
  ],
  exports:[TranslateModule, ConfirmDialogComponent, MainHeaderComponent, OnlyNumberDirective, MainFooterComponent, DefectCardModalComponent, SpinnerComponent, AlphabetsOnlyDirective, OnlyNumberWithoutDecimalDirective, CdkDropListActualContainerDirective, FilterPipe, LazyloadDirective],
  providers:[DatePipe, DataService, LoaderService, ConfirmDialogService, WindowService, ModalService, BnNgIdleService]
})
export class SharedModule { }
