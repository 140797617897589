export const environment = {
  production: false,
  //for ind prod
  // serverUrl: 'https://tpm-mobile-api-east.azurewebsites.net/api/',
  serverUrl: 'https://tpm.rich.com/api/',
  // serverUrl: 'https://tpm-api.azurefd.net/api/',
  hostKey: 'gVwmTHT6Aacm4wG-YHVSmIGiVD6SNcR55L3YyeqpRS0DAzFubkM-4Q==',
  socketUrl: '',
  idleTimeOut: 60000,
  sessionTimeOut: 1800,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  successMessageTimeout: 2000,
  errorMessageTimeout: 10000,
  messageTimeOut: 2000,
  receipeMaxLength: 20,
  searchLength: 3,
  addMessage: 'Added Successfully',
  updateMessage: 'Updated Successfully',
  copiedMessage: 'Copied Successfully',
  deleteMessage: 'Deleted Successfully',
  pageLength: 10,
  addCenterLineLength: 5,
  subscriptionKey: 'f44c104faf354c8fba3203a302b62833',
  location: 'eastus',
  toastOptions: {
    timeOut: 2000, positionClass: 'toast-center-center', closeButton: true
  },
  longToastOptions: {
    timeOut: 5000, positionClass: 'toast-center-center', closeButton: true
  },
  staticToastOptions: {
    timeOut: 0, positionClass: 'toast-center-center', closeButton: true
  },
  appInsights: {
    //for Prod
    instrumentationKey: 'c21910eb-7be8-495c-9f9b-987218d0ec68'
  },

  //for ind prod
  msalAuthConfig: {
    auth: {
      clientId: 'e8865ca1-f3f5-4332-90e4-c2b8d2f0b809', // This is your client ID
      authority: 'https://login.microsoftonline.com/a3ecbd48-a972-4e71-bae3-1339ccdfdd34',
      validateAuthority: true,
      redirectUri: 'https://tpm.rich.com',
      postLogoutRedirectUri: 'https://tpm.rich.com',
      navigateToLoginRequestUrl: true,
    }
  }
};
