import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ToastrService } from 'ngx-toastr';
import { empty, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { NetworkService } from 'src/app/core/services/network.service';
import { LoginService } from 'src/app/features/login-process/services/login.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { environment } from 'src/environments/environment';
import { UUID } from 'angular2-uuid';
import { CommonService } from 'src/app/shared/services/common.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { Console } from 'console';
var timeZone: any = localStorage.getItem('timeZone');
@Component({
  selector: 'app-defect-card-modal',
  templateUrl: './defect-card-modal.component.html',
  styleUrls: ['./defect-card-modal.component.scss'],
  providers: [NgbActiveModal]
})
export class DefectCardModalComponent implements AfterViewChecked, OnInit {
  env = environment
  @ViewChild('defectCardModal', { static: false }) defectCardModal: TemplateRef<Object>;
  @Input() modalData: any;
  @Input() orientationType: any;
  @Input() cilTaskData: any;
  @Output() isDefectAdded = new EventEmitter<any>();
  imgURL: any = '';
  imgShowURL: any = '';

  uploadedImage: File;
  uploadedResizedImage: File;
  lineList: any[] = [];
  amAreaList: any[] = [];
  equipmentList: any[] = [];
  defectTypeList: any[] = [];
  fixedStatusList: any[] = [];
  model:any = {};
  missingValidationMessages: string[] = [];
  intialCILTaskLoadFlag = false;
  isShow: boolean = false;
  isChanged: boolean = false;
  isButtonDisabled: boolean = true;
  isEquipementShow: boolean = true;
  isDisabledLine: boolean = false;
  isDisableAmArea: boolean = false;
  isDevice: boolean = false;
  formatter = (x: {text: string}) => (x.text==undefined)?'':x.text;
  public options_line: any;
  public options_defect_type: any;
  dropdownSettings: any = {};
  public options_fixed: any;
  height: any;
  width: any;
  options_equip: {};
  selectedObj: any;
  selectedAMAreas: any[] = [];

  constructor(public sanitizer: DomSanitizer, private ng2ImgMax: Ng2ImgMaxService, private toastr: ToastrService, private netWorkSer: NetworkService, private loaderService: LoaderService, private ref: ChangeDetectorRef, private router: Router, private loginService: LoginService, private datePipe: DatePipe, public modal: NgbActiveModal, private route: ActivatedRoute, private renderer: Renderer2, private commonService: CommonService, private languageService: LanguageService, private translate: TranslateService) { }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event?: any) {
    switch(window.orientation) {
        case -90:
          this.rotateImage();
          break;
    }
    console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
    console.log("the orientation of the device is now " + event.target.screen.orientation.type);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isDevice = false;
    } else {
      this.isDevice = true;
    }
  }

  ngOnInit(): void {
    this.model = {
      line: '',
      line_name: '',
      am_area_id: '',
      am_area_name: '',
      selected_am_area_obj: [],
      name: '',
      shift: '',
      ShiftNameTDV: '',
      date: '',
      mo: '',
      unit_operation: '',
      equipement_id: '',
      equipement_number: '',
      defect_type: '',
      defect_code: '',
      defect_type_name: '',
      fixed_id: 0,
      fixed_name: '',
      fixed: '',
      brief_description: '',
      defect_image: ''
    }

    if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isDevice = false;
    } else {
      this.isDevice = true;
    }

    this.model.line = sessionStorage.getItem('line_id');
    if(this.model.line==0) {
      this.router.navigate(['tpm-mobile']);
    } else if(this.model.line==null) {
      this.router.navigate(['tpm-mobile']);
    } else {
      if(this.route.snapshot.url[0].path == 'defect-handling')
        this.isDisabledLine = false;
      else
        this.isDisabledLine = true;
    }
    this.getDefectCardHeader();
    this.getLineList();
    this.getAMAreaList(this.model.line);
    this.getDefectType();
    this.getFixedStatusList();
    this.model.date = new Date();
    this.options_line = {
      width: '100%',
      dropdownCssClass: "line-id",
      minimumResultsForSearch: -1
    }
    this.options_defect_type = {
      width: '100%',
      dropdownCssClass: "defect-type",
      minimumResultsForSearch: -1
    }
    this.options_fixed = {
      width: '100%',
      dropdownCssClass: "fixed",
      minimumResultsForSearch: -1
    }
    this.options_equip = {
      width: '100%',
      dropdownCssClass: "bigdrop"
    };
    
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: false,
      idField: 'id',
      textField: 'amAreaName',
      itemsShowLimit: 100,
      maxHeight: 197,
      allowSearchFilter: true,
      searchPlaceholderText: 'Search',
      clearSearchFilter: true,
      noDataAvailablePlaceholderText: 'No Record Found'
    };
  }

  ngAfterViewChecked() :void {
    this.ref.detectChanges();
  }

  // Get line list
  getLineList() {
    this.loaderService.display(true);
    this.commonService.getLineList().subscribe(response => {
      this.loaderService.display(false);
      if(response.data.length > 0){
        this.lineList = response.data;
        for (let index = 0; index < this.lineList.length; index++) {
          const element = this.lineList[index];
          element.text = element.lineName;
        }
      }else{
        this.lineList = [];
      }
    })
  }

  // Get AM Area
  getAMAreaList(line_id: any) {
    this.loaderService.display(true);
    this.lineList.filter(data => {
      if(data.id == line_id) {
        this.model.line_name = data.lineName;
      }
    });
    this.model.equipement_id = '';
    this.model.equipement_number = '';
    this.model.equipement_name = '';
    this.model.unit_operation = '';

    let url = 'GetLineAMArea/' + line_id;
    this.netWorkSer.httpReq('get', url).subscribe(response => {
      this.loaderService.display(false);
      this.model.selected_am_area_obj = [];
      response.push(this.createEmptyAMArea(response));
      let AllAMArea = this.createEmptyAMArea(response);
      AllAMArea.id = 0;
      AllAMArea.amAreaName = "Select All";
      response.push(AllAMArea);
      if(response.length > 0){
        this.amAreaList = response;        
        for (let index = 0; index < this.amAreaList.length; index++) {
          const element = this.amAreaList[index];
          element.text = element.amAreaName;
        }
        this.model.am_area_id = sessionStorage.getItem('am_area_id');
        if(this.model.am_area_id!='') {
          if(this.route.snapshot.url[0].path == 'defect-handling')
            this.isDisableAmArea = false;
          else
            this.isDisableAmArea = false;
        }
      }else{
        this.amAreaList = [];
      }
      //Filter selections, and assign display text
      this.amAreaList = this.amAreaList
                        .filter((element: any, index: number, array: any) => {
                          return this.amAreaList.find((element2) => { return element2.id == element.id;});
                        });
      this.model.selected_am_area_obj.push(this.amAreaList.find(element => element.id == this.model.am_area_id));
      this.getEquipmentCLList(this.model.selected_am_area_obj[0]);
      this.getDefectCardHeader();
    })
  }

  private createEmptyAMArea(response: any) {
    var emptyAMArea =  JSON.parse(JSON.stringify(response[0]));
    emptyAMArea.amAreaCreatedBy = null;
    emptyAMArea.amAreaCreatedDate = "0001-01-01T00:00:00";
    emptyAMArea.amAreaDescription = null;
    emptyAMArea.amAreaIsDeleted = false;
    emptyAMArea.amAreaName = "No AM Area";
    emptyAMArea.amAreaRanking = null;
    emptyAMArea.amAreaSAP = null;
    emptyAMArea.areaType = null;
    emptyAMArea.id = -1;
    emptyAMArea.lineId = -1;
    emptyAMArea.lineIsDeleted = false;
    emptyAMArea.lineName = null;
    emptyAMArea.oldSafetyMapImageUrl = null;
    emptyAMArea.safetyMapImageUrl = null;
    emptyAMArea.safetyMapImageUrlByte = null;
    return emptyAMArea;
  }

  //get equipment list
  getEquipmentCLList(am_area: any) {
    let amAreaId: string = am_area.id;
    if(this.model.line!='' && (am_area!='' && am_area!=null)) {
      this.amAreaList.filter(data => {
        if(data.id == am_area) {
          
          this.model.am_area_name = data.amAreaName;
        }
      });
      this.loaderService.display(true);
      this.commonService.getEquipmentCLList(this.model.line, amAreaId).subscribe(response => {
        if(response.data.length > 0) {
          this.equipmentList = [];
          this.equipmentList.push(...response.data);

          this.model.is_equipement = false;
          this.model.equipement_id = '';
          this.model.equipement_number = '';
          this.model.equipement_name = '';
          this.model.unit_operation = '';
          for (let index = 0; index < this.equipmentList.length; index++) {
            const element = this.equipmentList[index];
            element.equipmentNumber = element.equipmentNumber;
            element.id = element.id;
            element.text = element.equipmentNumber + ' - ' + element.equipmentName;
          }
          if(this.cilTaskData!=undefined) {
            this.equipmentList.forEach(obj => {
              if (obj.equipmentNumber == this.cilTaskData.EquipmentNumber) {
                this.intialCILTaskLoadFlag = true
                this.model.unit_operation = this.cilTaskData.EquiDescription;
                this.model.equipement_id = this.cilTaskData.EquipmentId;
                this.model.equipement_number = this.cilTaskData.EquipmentNumber;
                this.model.equipement_name = this.cilTaskData.EquiDescription;
              }
              else if (this.intialCILTaskLoadFlag != true) {
                this.model.unit_operation = this.equipmentList[0].equipmentName;
                this.model.equipement_id = this.equipmentList[0].id;
                this.model.equipement_number = this.equipmentList[0].equipmentNumber;
                this.model.equipement_name = this.equipmentList[0].equipmentName;
              }
            });
          }
          else {
            this.model.unit_operation = this.equipmentList[0].equipmentName;
            this.model.equipement_id = this.equipmentList[0].id;
            this.model.equipement_number = this.equipmentList[0].equipmentNumber;
            this.model.equipement_name = this.equipmentList[0].equipmentName;
          }

          this.model.is_equipement = true;
          this.isEquipementShow = false;

          this.loaderService.display(false);
        } else {
          this.equipmentList = [];
          // if(response.error!='')
          //   this.toastr.error('', response.error, environment.toastOptions);
          this.loaderService.display(false);
        }
      });
    }
  }

  showEquipementSelecttion() {
    this.isEquipementShow = true;
    this.model.is_equipement = false;
  }

  //search equipement code
  searchEquipmentCode: OperatorFunction<string, readonly {id: any, text: any}[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    filter(term => term.length >= 1),
    map(term =>
      this.equipmentList.filter(equipement => new RegExp(term, 'mi').test(equipement.text)).slice(0, 10)
    ),
  )

  //select equipment
  selectEquipment(e: any) {
    var result = this.equipmentList.filter((obj:any) => {
      return obj.id == e;
    })
    this.selectedObj = result.length > 0 ? result[0] : {};
    if(this.selectedObj != undefined) {
      this.model.is_equipement = true;
      this.model.equipement_id = this.selectedObj.id;
      this.model.equipement_number = this.selectedObj.equipmentNumber;
      this.model.equipement_name = this.selectedObj.equipmentName;
      this.modelChanged();
    }
  }

  //get defect type
  getDefectType() {
    this.loaderService.display(true);
    this.netWorkSer.httpReq('get', "GetDefectType/"+this.languageService.getLanguage()).subscribe(response => {
      if(response.data.length > 0){
        this.defectTypeList = response.data;
        //this.defectTypeList.unshift({ id: 0, type: 'Select Defect Type' });
        for (let index = 0; index < this.defectTypeList.length; index++) {
          const element = this.defectTypeList[index];
          element.text = element.type;
        }
        this.loaderService.display(false);
      } else {
        this.defectTypeList = [];
        this.loaderService.display(false);
      }
    })
  }

  //get fixed status
  getFixedStatusList() {
    this.loaderService.display(true);
    this.netWorkSer.httpReq('get', "GetFixedStatus/"+this.model.fixed_id).subscribe(response => {
      if(response.data.length > 0){
        this.fixedStatusList = response.data;
        for (let index = 0; index < this.fixedStatusList.length; index++) {
          const element = this.fixedStatusList[index];
          element.text = element.statusType;
        }
        this.loaderService.display(false);
      } else {
        this.fixedStatusList = [];
        this.loaderService.display(false);
      }
    })
  }

  //get defect card header data
  getDefectCardHeader() {
    this.loaderService.display(true);
    this.netWorkSer.httpReq('get', "GetDefectCardHeader/"+this.model.line).subscribe(response => {
      if(response.data.length > 0) {
        this.model.name = this.loginService.getUserFullName();
        this.model.shift = response.data[0].shift;
        this.model.shiftNameTDV = response.data[0].shiftNameTDV;
        this.model.mo = response.data[0].mo;
        this.loaderService.display(false);
      }
    })
  }

  //preview image
  setImage(file: File, type: any) {
    this.isShow = true;
    $('#myCanvas').hide();
    var reader = new FileReader();
    reader.onload = (_event) => {
      if(type == false)
        this.imgURL = reader.result;
      else
        this.imgShowURL = reader.result;
    }
    reader.readAsDataURL(file);
    setTimeout(() => {
      this.onOrientationChange();
    }, 1000);

  }

  rotateImage() {
    this.loaderService.display(true);
    this.isShow = true;
    $('#output').hide();
    $('#myCanvas').show();
    let img = new Image();
    var defectImage: any = document.getElementById('output');
    img.src = defectImage.src;
    // Create a canvas object.
    let canvas: any = document.getElementById("myCanvas");

    // Create canvas context.
    let ctx = canvas.getContext("2d");

    // Assign width and height.
    canvas.width = img.width;
    canvas.height = img.height;

    ctx.translate(canvas.width / 2,canvas.height / 2);

    // Rotate the image and draw it on the canvas.
        // (I am not showing the canvas on the webpage.
    ctx.rotate(Math.PI);
    ctx.drawImage(img, -img.width / 2, -img.height / 2);

    console.log(ctx);
    console.log(canvas.toDataURL("image/png"));
    this.imgURL = canvas.toDataURL("image/png");
    this.loaderService.display(false);
  }

  //model value change
  modelChanged(type: any = '') {
    this.isChanged = false;
    this.isButtonDisabled = true;
    if(this.validateModel()) {
      this.isChanged = true;
      this.isButtonDisabled = false;
      //console.log("1");
    }
    if(type == 'image') {
      // this.loaderService.display(true);
      const fileInput: any = document.getElementById('defect_image');
      fileInput.addEventListener('change', (e: any) => {
        if(e.target.value!='') {
          var defectImageSplit: any = e.target.value.split('.');
          this.model.defect_image_name = defectImageSplit[0]+"_"+UUID.UUID()+"."+defectImageSplit[1];//e.target.value;
          let image = e.target.files[0];
          var imageSplit: any = image.name;
          this.uploadedImage = new File([image], imageSplit[0]+"_"+UUID.UUID()+"."+imageSplit[1]);
          this.setImage(this.uploadedImage, false);

          this.ng2ImgMax.resizeImage(image, 300, 160).subscribe(
            result => {
              var imageResizeSplit: any = result.name;
              this.uploadedResizedImage = new File([result], imageResizeSplit[0]+"_"+UUID.UUID()+"."+imageResizeSplit[1]);
              this.setImage(this.uploadedResizedImage, true);
            },
            error => {
              this.toastr.error('', error, environment.toastOptions);
            }
          )
        } else {
          this.loaderService.display(false);
        }
      });
    }
    if(type == 'defect') {
      this.defectTypeList.filter(data => {
        if(this.model.defect_type == data.id) {
          this.model.defect_code = data.code;
          this.model.defect_type_name = data.type;
        }
      });
    }
    if(type == 'fixed') {
      this.fixedStatusList.filter(data => {
        if(this.model.fixed == data.id) {
          this.model.fixed_id = data.id;
          this.model.fixed_status = data.statusType;
        }
      });
    }
  }

  // Validate required form fields
  validateModel () {
    let validFlag = true;
    this.missingValidationMessages = [];

    if (this.model.line == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.Line_Validation_Error'));
      validFlag = false;
    }
    if (this.model.selected_am_area_obj.id == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.AM_Area_Validation_Error'));
      validFlag = false;
    }
    if (this.model.unit_operation == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.Unit_Of_Operation_Validation_Error'));
      validFlag = false;
    }
    if (this.model.defect_type == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.Defect_Type_Validation_Error'));
      validFlag = false;
    }
    if (this.model.fixed == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.Fixed_Validation_Error'));
      validFlag = false;
    }
    if (this.model.brief_description == '') {
      this.missingValidationMessages.push(this.translate.instant('Defect.Description_Validation_Error'));
      validFlag = false;
    }

    return validFlag;
  }

  //remove image
  removeImage() {
    this.loaderService.display(true);
    this.model.defect_image = '';
    setTimeout(() => {
      this.isShow = false;
      this.loaderService.display(false);
    }, 500);
  }

  //submit form
  onSubmit(form: any) {
    if(form.form.valid) {
      this.loaderService.display(true);

      setTimeout(() => {
        // added to stop loader while creating defect
        this.modalData.close();
        this.isDefectAdded.emit(true);
        this.loaderService.display(false);
      }, 1000);

      var selectedAMArea = this.amAreaList.find((element) => element.id == this.model.am_area_id);

      if(this.model.is_equipement) {
        let plant_id_sap =  localStorage.getItem('plant_id_sap');
        var defectCardParams: any = {
          "LineId": Number(this.model.line),
          "AMAreaId": Number(this.model.selected_am_area_obj[0].id),
          "CreatedBy": this.loginService.getUserName(),
          "Shift": this.model.shift,
          "ShiftNameTDV": this.model.shiftNameTDV,
          "CreatedDate": this.datePipe.transform(this.model.date, 'MM/dd/yyyy HH:mm', timeZone),
          "MaintOwner": this.model.mo,
          "EquipmentId": Number(this.model.equipement_id),
          "DefectTypeId": Number(this.model.defect_type),
          "FixedStatus": (this.model.fixed_id),
          "FixedStatusType": this.model.fixed_status,
          "Description": this.model.brief_description,
          "DefectImage": (this.model.defect_image_name!=undefined)?this.model.defect_image_name:'',
          "CILJobId": 0,
          "TaskId": 0,
          "ImageUrlbyte": (this.imgURL!='')?this.imgURL.split(',')[1]:'',
          "Name": this.model.name,
          "LineName": this.model.line_name,
          "AmareaName": this.model.selected_am_area_obj[0].amAreaName,
          "EquipmentName": (this.model.unit_operation.equipmentName!=undefined)?this.model.unit_operation.equipmentName:this.model.equipement_name,
          "EquipmentNumber": this.model.equipement_number.toString(),
          "DefectType": this.model.defect_type_name,
          "DefectTypeCode": this.model.defect_code,
          "PLANPLANT": plant_id_sap,
          "AMAreaIsShared": selectedAMArea.isShared
        }
        this.netWorkSer.httpReq('post', 'DefectCardOperations', '&DescriptionLanguage='+this.languageService.getLanguage(), defectCardParams).subscribe(resp => {
          if(resp.success != '') {
            // this.modalData.close();
            this.isDefectAdded.emit(true);
            // this.toastr.success('', resp.success, environment.toastOptions);
            // this.loaderService.display(false);
          } else {
            this.isDefectAdded.emit(false);
            // this.toastr.error('', resp.error, environment.toastOptions);
            // this.loaderService.display(false);
          }
        });
      } else {
        this.loaderService.display(false);
        this.toastr.error('', 'Please select proper Unit Operation', environment.toastOptions);
      }
    }
  }

  removeDeselectedEquipment(am_area: any){
    this.equipmentList = this.equipmentList.filter(equipment => equipment.amAreaName !== am_area.amAreaName && am_area.id > 0);
    if(this.equipmentList.length == 0 || am_area.id <= 0){
      this.model.unit_operation = "";
      this.model.equipement_id = "";
      this.model.equipement_number = "";
      this.model.equipement_name = "";
      this.model.is_equipement = false;
      this.isEquipementShow = true;
      this.showEquipementSelecttion();
    }
    else{
      this.model.unit_operation = this.equipmentList[0].equipmentName;
      this.model.equipement_id = this.equipmentList[0].id;
      this.model.equipement_number = this.equipmentList[0].equipmentNumber;
      this.model.equipement_name = this.equipmentList[0].equipmentName;
      this.model.is_equipement = true;
      this.isEquipementShow = false;
    }
    this.intialCILTaskLoadFlag = false
    this.modelChanged();
  }

  //close modal
  closeModal() {
    this.isDefectAdded.emit(false);
    this.modalData.close();
  }
}