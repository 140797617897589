<nav class="navbar navbar-expand-lg navbar-orange navbar-dark fixed-top">
  <div class="container-fluid">
    <a class="navbar-brand navbar-logo" href="#">
      <img src="assets/images/RICHS_Logo.svg" alt="logo">
      <span class="tpm_title">{{ 'Admin.title' | translate }} - {{selectedPlantName}} {{ line_name }}</span>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ms-auto mb-2 mb-md-0">
        <li class="nav-item">
          <a class="nav-link">
            <ng-select2 id="language" [data]="languages" [placeholder]="'Select Language'" [options]="optionLang"  name="language" class="text-left language-select" [(ngModel)]="currentLang" (valueChanged)="switchLang($event)" ></ng-select2>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled">
            <div class="username">
              <span>{{userName}}</span>
              <i class="fa fa-user-o"></i>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>