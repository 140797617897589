import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../core/services/network.service';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
})
export class HealthCheckComponent implements OnInit {
  healthCheck: any = '';
  healthCheckStatus: any = '';
  constructor(private netWorkSer: NetworkService) { }

  ngOnInit(): void {
    this.getHealthCheck();
  }

  getHealthCheck() {
    this.netWorkSer.httpReq('get', 'HealthCheckAPI').subscribe(response => {
      console.log(response);
      if(response.status == '200') {
        this.healthCheckStatus = response.status;
      } else {
        this.healthCheckStatus = '500 error code';
        response.status = 500;
      }
      this.healthCheck = response;
    });
  }

}