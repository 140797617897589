<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important; position: absolute!important; z-index: 100000!important;">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div *ngIf="message?.type == 'confirm'" class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-center confirm-message">{{ 'Admin.'+message.messageType | translate: message }}</p><br>
                        <p class="text-center confirm-sub-message" *ngIf="message.messageType == 'plantDisableMessage' || message.messageType == 'lineDisableMessage' || message.messageType == 'deleteOPLPMessage'">{{ 'Admin.'+message.text | translate }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="confirm-button">
                            <a class="mr-2" (click)="message.yesFn()">
                                <button class="btn btn-yes">{{ 'Centerline.yes' | translate }}</button>
                            </a>
                            <a (click)="message.noFn()">
                                <button class="btn btn-no">{{ 'Centerline.no' | translate }}</button>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>