import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FeaturesRoutingModule } from './features-routing.module';
import { FeatureComponent } from './feature.component';
import { CenterLineComponent } from './center-line/center-line.component';
import { SharedModule } from "./../shared/shared.module";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CilTasksComponent } from './cil-tasks/cil-tasks.component';
import { DefectComponent } from './defect/defect.component';
import { DlmComponent } from './dlm/dlm.component';
import { OplComponent } from './opl/opl.component';

// required for AOT compilation
export function featureHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [FeatureComponent, CenterLineComponent, CilTasksComponent, DefectComponent, DlmComponent, OplComponent],
  imports: [
    CommonModule,
    FeaturesRoutingModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: (featureHttpLoaderFactory),
          deps: [HttpClient],
      },
      isolate: true,
    }),
    PdfViewerModule
  ],
  // exports:[TranslateModule]
})
export class FeaturesModule { }
