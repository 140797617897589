import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-center-line',
  templateUrl: './center-line.component.html',
  styleUrls: ['./center-line.component.scss']
})
export class CenterLineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
