<div>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'CIL.defect_card' | translate }}</h4>
        <button type="button" class="modal_close close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <form method="post" (ngSubmit)="onSubmit(defectCardForm)" #defectCardForm="ngForm">
            <div class="row">
                <div [ngClass]="isDevice ? 'col-xl-2 col-lg-4 col-md-6 col-sm-6' : 'col-xl-2 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group select2-editable-field line">
                        <label class="red_label">{{ 'Admin.line' | translate }} <span class="mandatory">*</span></label>
                        <ng-select2 [data]="lineList" [placeholder]="'Select Line'" [options]="options_line" [(ngModel)]="model.line" (valueChanged)="getAMAreaList($event)" name="line" id="line" (ngModelChange)="modelChanged()" required [disabled]="isDisabledLine"></ng-select2>
                    </div>
                </div>
                <div [ngClass]="isDevice ? 'col-xl-3 col-lg-4 col-md-6 col-sm-6' : 'col-xl-3 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group select2-editable-field area am-area-defect">
                        <label class="red_label">{{ 'Admin.am_area' | translate }} <span class="mandatory">*</span></label>
                        <ng-multiselect-dropdown [placeholder]="'Select AM Area'" [settings]="dropdownSettings" [data]="amAreaList" [(ngModel)]="this.model.selected_am_area_obj" (onSelect)="getEquipmentCLList($event)" (onDeSelect)="removeDeselectedEquipment($event)" name="am_area" id="am_area" (ngModelChange)="modelChanged()" [ngModelOptions]="{ standalone: true }" required [disabled]="isDisableAmArea" [ngClass]="{ 'modal-multiselect-grow': true }"></ng-multiselect-dropdown>
                    </div>
                </div>
                <div [ngClass]="isDevice ? 'col-xl-2 col-lg-4 col-md-6 col-sm-6' : 'col-xl-2 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group">
                        <label class="red_label">{{ 'CIL.name' | translate }}</label>
                        <div class="border-top-label">{{ model.name }}</div>
                    </div>
                </div>
                <div [ngClass]="isDevice ? 'col-xl-1 col-lg-4 col-md-6 col-sm-6' : 'col-xl-1 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group">
                        <label class="red_label">{{ 'Admin.shift' | translate }}</label>
                        <div class="border-top-label">{{ model.shiftNameTDV }}</div>
                    </div>
                </div>
                <div [ngClass]="isDevice ? 'col-xl-2 col-lg-4 col-md-6 col-sm-6' : 'col-xl-2 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group">
                        <label class="red_label">{{ 'CIL.date_and_time' | translate }}</label>
                        <div class="border-top-label">{{ model.date  | date:'MM/dd/yyyy HH:mm': env.timeZone }}</div>
                    </div>
                </div>
                <div [ngClass]="isDevice ? 'col-xl-2 col-lg-4 col-md-6 col-sm-6' : 'col-xl-2 col-lg-4 col-md-6 col-sm-6'">
                    <div class="form-group">
                        <label class="red_label">{{ 'CIL.maint_owner' | translate }}</label>
                        <div class="border-top-label">{{ model.mo }}</div>
                    </div>
                </div>
            </div>

            <div class="card card-body mt-lg-2 mt-xl-3 bg-light">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group select2-editable-field">
                                    <label class="red_label width-100">{{ 'CIL.select_unit_operation' | translate }} <span class="mandatory">*</span> <span class="pull-right" *ngIf="!isEquipementShow" (click)="showEquipementSelecttion()"><i class="fa fa-pencil"></i></span></label>
                                    <ng-select2 [data]="equipmentList" placeholder="{{ 'CIL.select_unit_operation_placeholder' | translate }}" [options]="options_equip" [(ngModel)]="model.unit_operation" (valueChanged)="selectEquipment($event)" [ngModelOptions]="{standalone: true}" class="cl_point_seelct2" *ngIf="isEquipementShow"></ng-select2>

                                    <input type="text" class="form-control editable-fields" name="unit_operation" id="unit_operation" [(ngModel)]="model.unit_operation" placeholder="{{ 'CIL.select_unit_operation_placeholder' | translate }}" (ngModelChange)="modelChanged()" required *ngIf="!isEquipementShow" readonly placement="top" [ngbTooltip]="model.unit_operation" container="body" triggers="click:blur">

                                    <input type="hidden" name="is_equipement" id="is_equipement" [(ngModel)]="model.is_equipement">
                                    <input type="hidden" name="equipement_id" id="equipement_id" [(ngModel)]="model.equipement_id">
                                    <input type="hidden" name="equipement_number" id="equipement_number" [(ngModel)]="model.equipement_number">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group select2-editable-field defect-card">
                                    <label class="red_label">{{ 'CIL.select_defect_type' | translate }} <span class="mandatory">*</span></label>
                                    <ng-select2 [data]="defectTypeList" [placeholder]="'Select Defect Type'" [options]="options_defect_type" [(ngModel)]="model.defect_type" name="defect_type" id="defect_type" (ngModelChange)="modelChanged('defect')" required></ng-select2>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group select2-editable-field fixed">
                                    <label class="red_label">{{ 'CIL.fixed' | translate }} <span class="mandatory">*</span></label>
                                    <ng-select2 [data]="fixedStatusList" [placeholder]="'Defect Fixed?'" [options]="options_fixed" [(ngModel)]="model.fixed" name="fixed" id="fixed" (ngModelChange)="modelChanged('fixed')" required></ng-select2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mb-sm-3">
                        <div class="card card-body">
                            <div class="col-lg-12">
                                <label class="red_label">{{ 'CIL.brief_description_of_defect' | translate }} <span class="mandatory">*</span></label>
                                <textarea name="brief_description" id="brief_description" class="form-control editable-fields h-bd" [(ngModel)]="model.brief_description" placeholder="{{ 'CIL.brief_description_of_defect_placeholder' | translate }}" (ngModelChange)="modelChanged()" required maxlength="130"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="card card-body no-padding mb-10">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group no-margin-bottom" *ngIf="!isShow" style="outline: 0;">
                                        <input type="file" class="editable-fields" name="defect_image" id="defect_image" class="inputfile" accept="image/*" capture="environment" [(ngModel)]="model.defect_image" (ngModelChange)="modelChanged('image')">
                                        <label for="defect_image" style="outline: 0;">
                                            <div class="defect">
                                                <img src="/assets/images/Icon-camera.svg" alt="Defect Image" class="cust-img cursor" style="width: fit-content; height: 50px;"><br/>
                                                {{ 'Defect.defect_image' | translate }}
                                            </div>
                                        </label>
                                    </div>

                                    <div class="form-group no-margin-bottom" *ngIf="isShow" style="outline: 0;">
                                        <div class="defect_card_image">
                                            <div class="cross cursor" (click)="removeImage();">
                                                <i class="fa fa-times fa-2x red_label"></i>
                                            </div>
                                            <canvas id="myCanvas" width="480" height="320"></canvas>
                                            <img [src]="sanitizer.bypassSecurityTrustUrl(imgShowURL)" id="output" alt="Defect Image" class="cust-img cursor" style="width: fit-content;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-right form-group">
                        <button class="btn btn-default" [class]="isChanged ? 'btn-enabled' : 'btn-grey'" name="submit" id="submit" [disabled]="!defectCardForm.form.valid || isButtonDisabled">{{ 'Defect.submit' | translate }}</button>
                    </div>
                    <div *ngFor="let error of missingValidationMessages" class="col-lg-12 text-right">
                        <h5 class="error-list">
                            {{ error }}
                        </h5>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>