import { Injectable, TemplateRef, ViewChild } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginService } from 'src/app/features/login-process/services/login.service';
import { LoaderService } from './loader.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {

  constructor(private bnIdle: BnNgIdleService, private loginService: LoginService, private loaderService: LoaderService) { }

  checkSession() {
    this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
      console.log("isTimedOut = " + isTimedOut);
      if (isTimedOut) {
        Swal.fire({
          icon: 'info',
          title: 'Session Expired',
          showConfirmButton: false,
          timer: environment.successMessageTimeout
        });
        setTimeout(() => {
          this.loaderService.display(true);
          this.bnIdle.stopTimer();
          this.loginService.logout();
        }, environment.successMessageTimeout);
      }
    });
  }

  showAlertForSessionOut() {
    Swal.fire({
      icon: 'info',
      title: 'Session Expired',
      showConfirmButton: false,
      timer: environment.successMessageTimeout
    });
    setTimeout(() => {
      this.loginService.logout();
    }, environment.successMessageTimeout);
  }
}
