import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkService } from './services/network.service';
import { AdalService } from './services/adal.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers:[
    NetworkService,
    AdalService
  ]
})
export class CoreModule { }
