import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumberWithoutDecimal]'
})
export class OnlyNumberWithoutDecimalDirective {
  private regex: RegExp = new RegExp(/^[0-9]\d*$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab'];
  public text: any;

  constructor(private _el: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      return false
    }
    const current: string = this._el.nativeElement.value;
    const next: string = current.concat(event.key);

    if ((next && !String(next).match(this.regex))) {
      event.preventDefault();
    }
    return true
  }
}
