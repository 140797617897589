import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements AfterViewChecked, OnInit {
  showOverlay: Boolean = false;
  toaster: any;
  spinner: any;
  message: string='';
  showLoader: boolean = false;
  constructor(private dataService: DataService, private loaderService: LoaderService, private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.spinner = this.dataService.on('loader', this.setFlag);
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
  }

  ngAfterViewChecked() :void {
    this.ref.detectChanges();
  }

  setFlag = (flag: Boolean) => {
    this.showOverlay = flag;
  };

}
