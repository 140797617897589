import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { DlmManagementFullScreenComponent } from './dlm-management-full-screen/dlm-management-full-screen.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { MloChangeOverComponent } from './mlo-change-over/mlo-change-over.component';
import { FormCheckGuard } from './shared/guards/form-check.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: "./features/features.module#FeaturesModule",
    //canActivate: [MsalGuard]
  },
  {
    path: 'dlm-dashboard-user/:line',
    component: DlmManagementFullScreenComponent,
    // canActivate: [MsalGuard]
  },
  {
    path: 'dlm-dashboard-user',
    component: DlmManagementFullScreenComponent,
    // canActivate: [MsalGuard]
  },
  {
    path: 'health-check',
    component: HealthCheckComponent,
  },
  {
    path: 'mlo-change-over',
    component: MloChangeOverComponent,
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FormCheckGuard]
})
export class AppRoutingModule { }
