import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable() export class ConfirmDialogService {
    private subject = new Subject<any>();

    confirmThis(message: string, type: any, pageType: any, yesFn: () => void, noFn: () => void): any {
        this.setConfirmation(message, type, pageType, yesFn, noFn);
    }

    setConfirmation(message: string, type: any, pageType: any, yesFn: () => void, noFn: () => void): any {
        const that = this;
        this.subject.next({
            type: 'confirm',
            text: message,
            messageType: type,
            pageType: pageType,
            yesFn(): any {
                    that.subject.next(); // This will close the modal
                    yesFn();
                },
            noFn(): any {
                that.subject.next();
                noFn();
            }
        });

    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}