<nav class="nav fixed-bottom footer-tabs">
    <a class="nav-link" [ngClass]="{'btn-enabled': isCLActive}" (click) = "go('/tpm-mobile/center-line')">
        <span class="btn red_label cust-btn" [ngClass]="{'btn-enabled': isCLActive}" >{{ 'Dashboard.Centerline' | translate }}</span>
    </a>

    <a class="nav-link" [ngClass]="{'btn-enabled': isCILActive}"  (click) = "go('/tpm-mobile/cil')">
        <span class="btn red_label cust-btn" [ngClass]="{'btn-enabled': isCILActive}" >{{ 'Dashboard.CIL' | translate }}</span>
    </a>

    <a class="nav-link" [ngClass]="{'btn-enabled': isDefectActive}"  (click) = "go('/tpm-mobile/defect')">
        <span class="btn red_label cust-btn"  [ngClass]="{'btn-enabled': isDefectActive}">{{ 'Dashboard.Defect' | translate }}</span>
    </a>

    <a class="nav-link" [ngClass]="{'btn-enabled': isOPLActive, 'opl': currLang == 'es'}" (click) = "go('/tpm-mobile/opl')">
        <span class="btn red_label cust-btn" [ngClass]="{'btn-enabled': isOPLActive}">{{ 'Dashboard.OPL' | translate }}</span>
    </a>

    <a class="nav-link" [ngClass]="{'btn-enabled': isDLMActive}" (click) = "go('/tpm-mobile/dlm')">
        <span class="btn red_label cust-btn" [ngClass]="{'btn-enabled': isDLMActive}">{{ 'Dashboard.DLM' | translate }}</span>
    </a>
</nav>
