import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

import { ComponentCanDeactivate } from '../interfaces/component-can-deactivate';

@Injectable({
  providedIn: 'root'
})
export class FormCheckGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // when navigating away from your angular app, the browser will show a generic warning message
      this.openConfirm()

      //confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }


  openConfirm(): any {
    Swal.fire({
      title: 'You have unsaved changes. Press Cancel to go back and save these changes',
      showConfirmButton: false,
      showDenyButton: false,
      showCloseButton: true,
      showCancelButton: true,
    })
    // .then((result) => {
    //   /* Read more about isConfirmed, isDenied below */
    //   if (result.isConfirmed) {
    //     // Swal.fire('Saved!', '', 'success')
    //   } else if (result.isDenied) {
    //     Swal.fire('Changes are not saved', '', 'info')
    //   }
    // })
  }

}
