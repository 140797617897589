import { Component, OnInit } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  href: any;
  isCLActive: boolean = false;
  isCILActive: boolean = false;
  isDefectActive: boolean = false;
  isOPLActive: boolean = false;
  isDLMActive: boolean = false;
  currLang: any;

  constructor(public translate: TranslateService, private router : Router, private languageService: LanguageService) { 
    this.currLang = this.languageService.getLanguage();
  }

  ngOnInit(): void {
    this.currLang = this.languageService.getLanguage();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      setTimeout(() => {
        this.currLang = this.languageService.getLanguage();
      }, 500);
    });
    // this.translate.use('en');
    this.href = this.router.url;
    if(this.href.includes('center-line')){
      this.isCLActive = true;
    }
    if(this.href.includes('cil')){
      this.isCILActive = true;
    }
    if(this.href.includes('defect')){
      this.isDefectActive = true;
    }
    if(this.href.includes('opl')){
      this.isOPLActive = true;
    }
    if(this.href.includes('dlm')){
      this.isDLMActive = true;
    }
  }

  go(url:any){
		this.router.navigate([url]); // navigate to other page
	}
}
