import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/services/network.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  lineList: any[] = [];
  amAreaList: any[] = [];
  constructor(private netWorkSer: NetworkService) { }

  // Get line list
  getLineList() {
    let plantId = sessionStorage.getItem('plant_id');
    let obj:any = {
      "plantId":plantId,
      "userInput":null,
      "isDisabled":false
    }
    return this.netWorkSer.httpReq('post', 'GetLineData', '',obj);
  }

  //Get AM area
  getAMAreaList(lineID: any) {
      return this.netWorkSer.httpReq('get', 'GetLinesHomeAMArea/' + lineID);
  }

    //Get AM area
    getAMAreaListWithShared(lineID: any) {
      return this.netWorkSer.httpReq('get', 'GetLinesAMAreaWithShared/' + lineID);
    }
  
  //get equipment list
  getEquipmentCLList(lineID: any, amArea: any) {
    var amAreaJSON = JSON.stringify(amArea);
    return this.netWorkSer.httpReq('get', 'GetEquipmentCLList/'+lineID+'/'+amAreaJSON);
  }

  search(nameKey: any, myArray: any){
    let languageArray: any = [];
    if(myArray && myArray.length > 0){
      for (var i=0; i < myArray.length; i++) {
        languageArray.push(myArray[i].LanguageCode);
      }
    }

    if(languageArray.length > 0) {
      if(languageArray.indexOf(nameKey) >= 0) {
        return myArray[languageArray.indexOf(nameKey)]
      } else {
        for (var j=0; j < myArray.length; j++) {
          if(myArray[j].IsPrimary === 1) {
            return myArray[j];
          }
        }
      }
    }else{
      return null;
    }
  }

  checkNotificationLine(lineId: any) {
    if(localStorage.getItem('userRole') == 'Super Admin' || localStorage.getItem('userRole') == 'Admin' || localStorage.getItem('userRole') == 'Coach') {
      return 1;
    } else {
      let notificationLines: any = localStorage.getItem('notificationLines');
      if(notificationLines!='') {
        if(notificationLines.indexOf(',') > -1) {
          let notificationLinesSplit: any = notificationLines.split(',');
          if(notificationLinesSplit.indexOf(lineId) > -1 && (localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO')) {
            return 1;
          } else if(localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO') {
            return 0;
          }
        } else {
          if(lineId != notificationLines && (localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO')) {
            return 0;
          } else if(localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO') {
            return 1;
          }
        }
      }
    }
    return 0;
  }

  checkNotificationLineWithRole(lineId: any) {
    if(localStorage.getItem('userRole') == 'Super Admin' || localStorage.getItem('userRole') == 'Admin' || localStorage.getItem('userRole') == 'Coach') {
      return 1;
    } else {
      let notificationLines: any = localStorage.getItem('notificationLines');
      if(notificationLines!='') {
        if(notificationLines.indexOf(',') > -1) {
          let notificationLinesSplit: any = notificationLines.split(',');
          if(notificationLinesSplit.indexOf(lineId) > -1 && (localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO')) {
            return 1;
          } else if(localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO') {
            return 0;
          }
        } else {
          if(lineId != notificationLines && (localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO')) {
            return 0;
          } else if(localStorage.getItem('userRole') == 'LO' || localStorage.getItem('userRole') == 'MO' || localStorage.getItem('userRole') == 'PO') {
            return 1;
          }
        }
      }
    }
    return;
  }

  changeNotificationLanguage(language: any) {
    this.netWorkSer.httpReq('put', 'SetAPIMessageLanguage/'+language).subscribe(response => {});
  }
}
