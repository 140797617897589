import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NetworkService } from '../core/services/network.service';

@Component({
  selector: 'app-mlo-change-over',
  templateUrl: './mlo-change-over.component.html',
  styleUrls: ['./mlo-change-over.component.scss']
})
export class MloChangeOverComponent implements OnInit {
  error: any = '';
  success: any = '';
  productCode: any = '';
  areaType: any = '';
  line: any = '';
  constructor(private netWorkSer: NetworkService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        if(Object.keys(params).length > 0) {
          console.log(params);
          this.productCode = params.productCode;
          this.areaType = params.areaType;
          this.line = params.line;
        }
      }
    );
    this.getMLOChangeOverValue();
  }

  getMLOChangeOverValue() {
    if(this.productCode!='' && this.areaType!='' && this.line!='') {
      this.netWorkSer.httpReq('get', 'MLOChangeOver/'+this.productCode+'/'+this.areaType+'/'+this.line).subscribe(res => {
        if(res.length>0) {
          this.success = res.success;
        } else {
          this.error = 'MLO Trigger Failed';
        }
        console.log(res);
      }, error => {
        this.error = error;
      });
    }
  }
}
