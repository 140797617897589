import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  language: any = '';
  constructor(private translate: TranslateService) { }

  getLanguage(): any {
    if(localStorage.getItem('language_selection') === 'null')
      return this.translate.currentLang;
    else
      return localStorage.getItem('language_selection');
  }

  getLanguageForDate(): any {
    if(localStorage.getItem('language_selection') == 'en')
      return localStorage.getItem('language_selection')+"-US";
    else
      return localStorage.getItem('language_selection')+"-ES";
  }
}
