import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/features/login-process/services/login.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { LanguageService } from 'src/app/shared/services/language.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  isShown:boolean=false;
  optionLang: {};
  selectedPlantName: any;
  loggedInUserData: any;
  userName: any;
  constructor(public translate: TranslateService, private router: Router, private loginService: LoginService,private confirmDialogService: ConfirmDialogService, private languageService: LanguageService, private commonService: CommonService) { }

  line_name: any = '';
  currentLang: any = '';
  languages: any = [
    { 'languageCode': 'en', 'id':'en', 'text':'English', 'languageName': 'English' },
    { 'languageCode': 'es', 'id':'es', 'text':'Spanish', 'languageName': 'Spanish' }
  ]

  ngOnInit(): void {
    this.selectedPlantName = sessionStorage.getItem('plant_name');
    this.currentLang = (this.languageService.getLanguage()!='')?this.languageService.getLanguage():this.translate.currentLang;
    // alert("this.currentLang = " + this.currentLang);
    setTimeout(() => {
      this.switchLang(this.currentLang);
    }, 500);
    this.optionLang = {
      width: '100%',
      dropdownCssClass: "bigdrop",
      minimumResultsForSearch: -1
    };
    this.line_name = (this.router.url!='/tpm-mobile/dlm/dlm-dashboard' && this.router.url!='/tpm-mobile/defect/defect-handling' && this.router.url!='/tpm-mobile/opl/opl-management')?" - "+sessionStorage.getItem('line_name'):'';

    this.loggedInUserData = sessionStorage.getItem('loggedInUserData');
    this.loggedInUserData = JSON.parse(this.loggedInUserData);
    this.userName = this.loggedInUserData.userName;
  }

  switchLang(lang: any) {
    // alert("lang = " + lang);
    this.translate.use(lang);
    localStorage.setItem('language_selection', lang);

    if(this.router.url!='/tpm-mobile/defect/defect-handling') {
      localStorage.setItem('language_selection', lang);
      if(!localStorage.getItem('isLoaded')) {
        localStorage.setItem('isLoaded', 'true');
        location.reload();
      } else {
        localStorage.removeItem('isLoaded');
      }
    }

    this.commonService.changeNotificationLanguage(lang);

  }

  // open logut confirmation modal
  openLogutModal() {
    this.confirmDialogService.confirmThis("Are you sure you want to logout?", 'logout', '',  () => {
      this.loginService.logout();
    }, function () {

    })
  }
}
