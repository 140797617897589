import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-opl',
  templateUrl: './opl.component.html',
  styleUrls: ['./opl.component.scss']
})
export class OplComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
