<nav class="navbar-expand-lg navbar-orange navbar-dark bg-dark navbar-dlm-dashboard-fs">
    <div class="container-fluid">
        <header class="h-header">
            <a class="col-6 navbar-brand navbar-logo me-auto">
                <img src="assets/images/RICHS_Logo.svg" alt="logo">
                <span class="tpm_title">{{ 'Admin.title' | translate }} - {{PlantName}} - {{ lineName }}</span>
            </a>

            <!-- <div class="col-4 text-center">
                <span class="dlm-dashboard-title">{{ 'DLM.dlm_dashboard' | translate }}</span>
            </div> -->
            
            <div class="col-6 d-flex justify-content-end">
                <div class="row d-flex justify-content-end">
                    <div class="col-auto d-flex align-items-center px-0">
                        <div *ngIf="line_name !== null">
                            <a routerLink="/tpm-mobile/dlm/dlm-dashboard" class="ic-tv" ngbTooltip="Exit full screen">
                                <label class="select_date_label cursor">
                                    <img src="./../../../../../assets/images/monitor_20.png">
                                </label>
                            </a>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="input-group">
                            <input type="text" class="form-control date-input" placeholder="mm/dd/yyyy" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" [maxDate]="maxDate" (dateSelect)="setDateEnable($event, 'change');" readonly autocomplete="off">
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</nav>
<app-spinner></app-spinner>


<div class="container-fluid dlm-dashboard-fs mt-2">
    <ng-container>
            <div class="row dlm-grid">
                <div class="card left">
                    <div class="card-body">
                        <div class="table-responsive table-dlm-fixed">
                            <table class="table table-bordered table-stripped table-dashboard">
                                <thead>
                                    <tr>
                                        <th colspan="4"></th>
                                        <th class="text-center grey cursor"><i class="fa fa-chevron-left" aria-hidden="true"></i></th>
                                        <th colspan="5" class="text-center red_label">{{ monthName }} {{ year }}</th>
                                        <th class="text-center grey cursor"><i class="fa fa-chevron-right" aria-hidden="true"></i></th>
                                    </tr>
                                    <tr>
                                        <th class="tw1">{{ 'Admin.kpi' | translate }}</th>
                                        <th class="tw2 text-center">{{ 'Admin.role' | translate }}</th>
                                        <th class="tw3">{{ 'Admin.description' | translate }}</th>
                                        <th class="tw4 text-center">{{ 'Admin.target' | translate }}</th>
                                        <th class="text-center red_label" *ngFor="let d of dateArray; let i=index" [ngClass]="{ 'blue_background': i === (dateArray.length-1) }">
                                            {{ d.previousDate }}<br/>
                                            {{ d.previousDateName }}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="dlmDashboardData_1.length === 0 && dlmDashboardData_newKpiList.length === 0">
                                    
                                </tbody>
                                <tbody>
                                    <ng-container *ngIf="dlmDashboardData_1.length > 0">
                                        <tr *ngFor="let dlmDashboard of dlmDashboardData_1;">
                                            <td>{{ dlmDashboard.KPI }}</td>
                                            <td class="text-center">{{ dlmDashboard.Role }}</td>
                                            <td *ngIf="dlmDashboard.Target == ''">
                                                {{ dlmDashboard.Description.split('/')[0] }}<br/><hr class="no-margin">{{ dlmDashboard.Description.split('/')[1] }}
                                            </td>
                                            <td *ngIf="dlmDashboard.Target != ''">
                                                {{ dlmDashboard.Description }}
                                            </td>
                                            <td class="text-center">{{ dlmDashboard.Target }}</td>
                                            <ng-container *ngFor="let dlmData of dlmDashboard.KPIListValues; let i = index">
                                                <ng-container *ngIf="dlmData.is_unschedule">
                                                    <td class="text-center" *ngIf="dlmData.Value.indexOf('/') > -1" [ngClass]="{'blue_background': i === (dateArray.length-1), 'unschedule_day': dlmData.is_unschedule }">
                                                        {{ dlmData.Value.split('/')[0] }}<br/><hr class="no-margin">{{ dlmData.Value.split('/')[1] }}
                                                    </td>
    
                                                    <td class="text-center" *ngIf="dlmData.Value.indexOf('/') === -1" [ngClass]="{'blue_background': i === (dateArray.length-1), 'target_blank': dlmData.check === '', 'unschedule_day': dlmData.is_unschedule }">
                                                        <span *ngIf="dlmData.Value == 'MTBF'">&#8734;</span>
                                                        <span *ngIf="dlmData.Value != 'MTBF'" [ngClass]="{ 'black_text': dlmData.show_value }">{{ dlmData.Value }}</span>
                                                    </td>
                                                </ng-container>
                                                <ng-container *ngIf="!dlmData.is_unschedule">
                                                    <td class="text-center" *ngIf="dlmData.Value.indexOf('/') > -1" [ngClass]="{'blue_background': i === (dateArray.length-1) }">
                                                        {{ dlmData.Value.split('/')[0] }}<br/><hr class="no-margin">{{ dlmData.Value.split('/')[1] }}
                                                    </td>
    
                                                    <td class="text-center" *ngIf="dlmData.Value.indexOf('/') === -1" [ngClass]="{'blue_background': i === (dateArray.length-1), 'target_completed': dlmData.check === 'Y', 'target_not_completed': dlmData.check === 'N', 'target_blank': dlmData.check === '' }">
                                                        <span *ngIf="dlmData.Value == 'MTBF'">&#8734;</span>
                                                        <span *ngIf="dlmData.Value != 'MTBF'" [ngClass]="{ 'black_text': dlmData.show_value }">{{ dlmData.Value }}</span>
                                                    </td>
                                                </ng-container>
                                            </ng-container>
    
                                        </tr>   
                                    </ng-container>
                                    <ng-container *ngIf="dlmDashboardData_newKpiList.length > 0">
                                        <tr *ngFor="let newKpi of dlmDashboardData_newKpiList;">
                                            <td class="yellow_background">
                                                {{ newKpi.KPI }}
                                                <input type="hidden" name="kpi_id" id="kpi_id" [(ngModel)]="newKpi.Kpiid">
                                            </td>
                                            <td class="yellow_background text-center">{{ newKpi.Role }}</td>
                                            <td class="yellow_background">{{ newKpi.Description }}</td>
                                            <td class="yellow_background text-center">{{ newKpi.Target }}</td>
    
                                            <td class="text-center" *ngFor="let newKpiDate of newKpi.NEWKPIListValues; let newKpiIndex = index;" [ngClass]="{ 'blue_background': newKpiIndex == newKpi.NEWKPIListValues.length-1, 'target_completed': newKpiDate.check === 'Y', 'target_not_completed': newKpiDate.check === 'N', 'unschedule_day': newKpiDate.is_unschedule }">
                                                <span *ngIf="newKpiIndex!=newKpi.NEWKPIListValues.length-1">{{ newKpiDate['Value'] }}</span>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>

                            </table>
                        </div>
                        
                    </div>
                </div>
                <div class="card right ps-0">
                    <div class="card-body">
                        <div class="card card-background top-3stops mb-2">
                            <div class="card-header">
                                <div class="dlm-card-header">
                                    <h5 class="card-title">{{ 'DLM.top_3_stops' | translate }}</h5>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                <table class="table dlm-table top-stops">
                                    <thead>
                                        <tr>
                                            <th class="tw1 pe-0">{{ 'DLM.details' | translate }}</th>
                                            <th class="tw2 pe-0">{{ 'DLM.events' | translate }}</th>
                                            <th class="tw3 pe-0">{{ 'DLM.dt' | translate }}</th>
                                            <th class="tw4 pe-0">{{ 'DLM.initial_cause' | translate }}</th>
                                            <th class="tw5">{{ 'DLM.system_linkage' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let topStop of dlmDashboardData_2_topStop; let i = index;">
                                            <td class="pe-0">
                                                <div class="top_stop_details height-style">
                                                    <label class="sr_no">{{ i+1 }}</label>
                                                    {{ topStop.Details }}
                                                </div>
                                            </td>
                                            <td class="pe-0">
                                                <div class="top-stop-details height-style">{{ topStop.Events }}</div>
                                            </td>
                                            <td class="pe-0">
                                                <div class="top-stop-details height-style">{{ topStop.Downtime }}</div>
                                            </td>
                                            <td class="pe-0">
                                                <textarea name="initial_cause" id="initial_cause" class="form-control height-style" [(ngModel)]="topStop.InitialCause" disabled></textarea>
                                            </td>
                                            <td class="select2-editable-field dlm-system-linkage">
                                                <textarea name="top_stop_system_linkage" id="top_stop_system_linkage" class="form-control height-style" [(ngModel)]="topStop.Linkage" disabled></textarea>

                                                <input type="hidden" name="top_stop_id" id="top_stop_id" [(ngModel)]="topStop.Id">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>

                        <div class="card card-background plan-2win mb-2">
                            <div class="card-header">
                                <div class="dlm-card-header">
                                    <h5 class="card-title">{{ 'DLM.plan_to_win_the_day' | translate }}</h5>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="tableFixedHead" [ngClass]="{ 'planToWinTable': dlmDashboardData_2_planToWin.length > planListScrollThreshhold }">
                                    <table class="table dlm-table">
                                        <thead>
                                            <tr>
                                                <th class="tw1 pe-0">{{ 'DLM.action' | translate }}</th>
                                                <th class="tw2 pe-0">{{ 'DLM.associated_kpi' | translate }}</th>
                                                <th class="tw3 pe-0">{{ 'DLM.owner' | translate }}</th>
                                                <th class="tw4">{{ 'DLM.time' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="dlmDashboardData_2_planToWin.length >= 1">
                                            <tr *ngFor="let planToWin of dlmDashboardData_2_planToWin; let indexPlanToWin = index">
                                                <td class="tw1 position-td pe-0">
                                                    <div class="col-table-actions">
                                            
                                                            <input type="checkbox" name="plan_details_check" id="plan_details_check_{{indexPlanToWin}}" class="check_details" value="false" [(ngModel)]="planToWin.planToWinCheck" [checked]="planToWin.planToWinCheck" disabled>
                                                            <span class="font-span" id="temp_id_{{indexPlanToWin}}">{{planToWin.details}}</span>
                                                        
                                                    </div>
                                                </td>
                                                <td class="tw2 position-td pe-0">
                                                    <input type="text" name="plan_associated_kpi" id="plan_associated_kpi" class="form-control" disabled [(ngModel)]="planToWin.kpi">
                                                </td>
                                                <td class="tw3 position-td pe-0">
                                                    <input type="text" name="plan_owner" id="plan_owner" class="form-control" disabled [(ngModel)]="planToWin.ownername">
                                                </td>
                                                <td class="tw4 position-td">
                                                    <div class="input-group table-input-group">
                                                        <input type="text" name="plan_time" id="plan_time" class="form-control" disabled [(ngModel)]="planToWin.time">
                                                        <button class="btn btn-outline-secondary table-checkbox" type="button" id="button-addon2">
                                                            <input class="form-check-input" [ngClass]="{  'checkmark-success': planToWin.status, 'checkmark-default': !planToWin.status, 'position-check': planToWin.details.length > 40 }" type="checkbox" name="plan_details_check" id="plan_details_check" *ngIf="planToWin.status" checked="checked" [(ngModel)]="planToWin.status" disabled>
                                                            <input class="form-check-input" [ngClass]="{ 'checkmark-success': planToWin.status, 'checkmark-default': !planToWin.status, 'position-check': planToWin.details.length > 40 }" type="checkbox" name="plan_details_check" id="plan_details_check" *ngIf="!planToWin.status" checked="checked" disabled>
                                                        </button>       
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="card card-background planned-1seven mb-2" [ngClass]="{ 'planned-1seven-anim': dlmDashboardData_2_planned1to7.length > planListScrollThreshhold }">
                            <div class="card-header">
                                <div class="dlm-card-header">
                                    <h5 class="card-title">{{ 'DLM.planned_1_to_7_days' | translate }}</h5>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="tableFixedHead planned1to7Table">
                                    <table class="table dlm-table no-top">
                                        <thead>
                                            <tr>
                                                <th class="tw1 pe-0">{{ 'DLM.action' | translate }}</th>
                                                <th class="tw2 pe-0">{{ 'DLM.owner' | translate }}</th>
                                                <th class="tw3">{{ 'DLM.date' | translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="dlmDashboardData_2_planned1to7.length >= 1">
                                            <tr *ngFor="let planned1to7 of dlmDashboardData_2_planned1to7; let indexPlannedDays = index;">
                                                <td class="tw1 position-td pe-0">
                                                    <div class="col-table-actions">
                                                        <input type="checkbox" name="planned_details_check" id="planned_details_check" class="check_details" value="false" [(ngModel)]="planned1to7.plannedCheck" [checked]="planned1to7.plannedCheck" disabled>
                                                        <span class="font-span" id="temp_id_planned_{{indexPlannedDays}}">{{planned1to7.details}}</span>
                                                    </div>
                                                </td>
                                                <td class="tw2 position-td pe-0">
                                                    <input type="text" name="planned_owner" id="planned_owner" class="form-control" disabled [(ngModel)]="planned1to7.ownername">
                                                </td>
                                                <td class="tw3 position-td">
                                                    <div class="input-group table-input-group">
                                                        <input type="text" name="planned_date" id="planned_date" class="form-control" disabled [(ngModel)]="planned1to7.date">
                                                        <button class="btn btn-outline-secondary table-checkbox" type="button" id="button-addon2">
                                                            <input class="form-check-input" [ngClass]="{ 'checkmark-success': planned1to7.status, 'checkmark-default': !planned1to7.status }" type="checkbox" name="planned_details_check" id="planned_details_check" *ngIf="planned1to7.status" checked="checked" [(ngModel)]="planned1to7.status" disabled>
                                                            <input class="form-check-input" [ngClass]="{ 'checkmark-success': planned1to7.status, 'checkmark-default': !planned1to7.status }" type="checkbox" name="planned_details_check" id="planned_details_check" *ngIf="!planned1to7.status" checked="checked" disabled>
                                                        </button>       
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                        <div class="comments">
                            <label class="red_label">{{ 'Admin.Comments' | translate }}<span class="mandatory">*</span></label>
                            <textarea name="notes" id="notes" class="form-control editable-fields" required [(ngModel)]="notes" disabled></textarea>
                        </div>
                    </div>
                </div>
            </div>
            
    </ng-container>

</div>