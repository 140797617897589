<div class="landing_body">
    <div class="container">

        <div class="h-100 row align-items-center">
            <div class="main-form">
                <div class="text-center">
                    <div class="text-center m-1">
                        <img src="/assets/images/RICHS_Logo.svg" width="130px" />
                        <h4>{{ 'Dashboard.title' | translate }}</h4>
                    </div>
                </div>
                <form>
                    <div class="form-container">
                        <div class="text-center m-4 p-5 murfreesboro">

                            <div class="row" *ngIf="error!=''">
                                <div class="col-lg-4">&nbsp;</div>
                                <div class="col-lg-4">
                                    <div class="card text-white bg-danger mb-3">
                                        <div class="card-body">
                                            <h5 class="card-title">
                                                <i class="fa fa-info-circle fa-2x"></i>
                                            </h5>
                                            <p class="card-text">{{ error }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">&nbsp;</div>
                            </div>

                            <div class="row" *ngIf="success!=''">
                                <div class="col-lg-4">&nbsp;</div>
                                <div class="col-lg-4">
                                    <div class="card text-white bg-success mb-3">
                                        <div class="card-body">
                                            <h5 class="card-title">
                                                <i class="fa fa-check-circle-o fa-2x"></i>
                                            </h5>
                                            <p class="card-text">{{ success }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>