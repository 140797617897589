import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  outgoingServicesCount = 0;
  constructor(private dataService: DataService) { }

  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  display(value: boolean) {
    this.status.next(value);
  }

  toggleLoader(serviceFlag: number, message?: string) {
    switch (serviceFlag) {
      case 0:
        //service returned response
        this.outgoingServicesCount -= 1;
        if (this.outgoingServicesCount <= 0) {
          this.outgoingServicesCount = 0;
          this.dataService.broadcast('loader', false, message);
        }
        break;
      case 1:
        //service called
        this.outgoingServicesCount += 1;
        this.dataService.broadcast('loader', true, message);
        break;
    }
  }
}
