import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cil-tasks',
  templateUrl: './cil-tasks.component.html',
  styleUrls: ['./cil-tasks.component.scss']
})
export class CilTasksComponent implements OnInit {

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.use('en');
  }

}
