import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { throwError, EMPTY } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';
import { environment } from 'src/environments/environment';

@Injectable()
export class ADTokenInterceptor implements HttpInterceptor {
  isTokenExpired = false;
  constructor(private router: Router, private msalService: MsalService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if(req.url.indexOf('https://api.cognitive.microsofttranslator.com/translate?api-version=3.0') !== 0 && req.url.indexOf('https://graph.microsoft.com/v1.0/users/') !== 0) {
      if (!this.isTokenExpired) {
        const authReq = this.addADTokenToHeaders(req);
        let tokenExpTime =+new Date(parseInt(this.msalService.getAccount()?.idToken['exp']) * 1000)
        let currentDateTime = +new Date();
        // console.log('exp',tokenExpTime)
        // console.log('current',currentDateTime)
        // console.log(req.url);
        if (tokenExpTime && currentDateTime && (currentDateTime>tokenExpTime)) {
          // To re-generate token
          return this.getADToken().mergeMap((response) => {
            if (response && response.type && response.type === 'Success') {
              const authTokenReq = this.addADTokenToHeaders(authReq);
              return this.handleRequestWithToken(next, authTokenReq);
            } else {
              this.isTokenExpired = true;
              return this.openSessionExpiredPopup();
            }
          });
        } else {
          return this.handleRequestWithToken(next, authReq);
        }
      } else {
        return this.openSessionExpiredPopup();
      }
    } else {
      return next.handle(req);
    }
  }

  // Open session expired popup
  openSessionExpiredPopup() {
    this.msalService.loginRedirect();
    return EMPTY;
  }

  // modify headers with AD token
  addADTokenToHeaders(req: HttpRequest<any>) {
    // const authToken = this.authService.getADToken();
    const authToken = localStorage.getItem('msal.idtoken');
    // Clone the request and replace the original headers with cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });

    return authReq;
  }

  // Get AD token silently
  getADToken(): Observable<any> {
    // To renew idToken, please pass clientId as the only scope
    const accessTokenRequest = {
      // scopes: ['user.read'],
      scopes: [environment.msalAuthConfig.auth.clientId],
      authority: environment.msalAuthConfig.auth.authority
    };
    return Observable.fromPromise(
      this.msalService.acquireTokenSilent(accessTokenRequest).then(async (payload) => {
        // this.authService.setTokenExpiryTime(payload); // Set token expiry time
        if (payload && payload.idToken && payload.idToken.rawIdToken) {
            localStorage.setItem('msal.idtoken', payload.idToken.rawIdToken);
        }
        return { type: 'Success', info: payload };
      }).catch((error) => {
        return { type: 'Error', info: error };
      })
    );
  }

  // handle request with proper token
  handleRequestWithToken(next: HttpHandler, authReq: any): Observable<any> {
    return next.handle(authReq);
  }
}
