import { Injectable } from '@angular/core';
import { MsalService} from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AdalService {

  constructor(private adalSer:MsalService) { }
  getExpiry(){
    return parseInt(this.adalSer.getAccount().idToken['exp']) * 1000
  }
}
