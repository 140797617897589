import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NetworkService } from './core/services/network.service';
import { LoginService } from './features/login-process/services/login.service';
import { LoggingService } from './logging.service';
import { LoaderService } from './shared/services/loader.service';
import { SessionTimeoutService } from './shared/services/session-timeout.service';
import { Event as RouterEvent, NavigationCancel, NavigationError } from '@angular/router'
import { Subject, Subscription } from 'rxjs';
import { BnNgIdleService } from 'bn-ng-idle';
export let browserRefresh = false;
export let navigationRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Rich\'s TPM Mobile';
  loggedIn: boolean = false;
  isUserLoggedIn: boolean = false;
  error: any;
  loggedInUserData: any;
  loginData: any;
  isNotFound: boolean = false;
  timeLeft: number = environment.sessionTimeOut;
  interval: any;
  subscription: Subscription;
  now: Date = new Date();
  intervalId: any;
  seconds: any = 0;
  minutes: any = 0;
  hour: any = 0;

  constructor(private sessionTimeoutService: SessionTimeoutService, private translate: TranslateService, private loginService: LoginService, private loaderService: LoaderService, private netWorkSer: NetworkService, private loggingService: LoggingService, private router: Router, private bnIdle: BnNgIdleService) {
    this.translate.use('en');
    this.router.events.subscribe((e : RouterEvent) => {
      this.navigationInterceptor(e);
    });

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        navigationRefresh = router.navigated;
      }
    });
  }

  ngOnInit() {
    // this.setProcessTime();
    // this.isInEmployee = true;
    // this.router.events.subscribe(e => {
    //   if (e instanceof NavigationStart) {
    //     if(e.url != '/dlm-dashboard-user' && e.url != '/dlm-dashboard-user/:line') {
    //       // this.sessionTimeoutService.checkSession(this.isInEmployee);
    //     }
    //     console.log(e.url);
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.display(true);
    }
    if (event instanceof NavigationEnd) {
      this.loaderService.display(false);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loaderService.display(false);
    }
    if (event instanceof NavigationError) {
      this.loaderService.display(false);
    }
  }
}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker('./app.worker', { type: 'module' });
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}