import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NetworkService } from 'src/app/core/services/network.service';
import { LoginService } from 'src/app/features/login-process/services/login.service';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import {NgbCarouselConfig, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { CustomDateParserFormatterService } from '../shared/services/custom-date-parser-formatter.service';
import { Observable } from 'rxjs-compat';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../shared/services/language.service';
var timeZone: any = localStorage.getItem('timeZone');
@Component({
  selector: 'app-dlm-management-full-screen',
  templateUrl: './dlm-management-full-screen.component.html',
  styleUrls: ['./dlm-management-full-screen.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatterService},
    NgbCarouselConfig
  ]
})
export class DlmManagementFullScreenComponent implements OnInit {
  @ViewChildren("itemElementPlanToWin") private itemElementPlanToWin: QueryList<ElementRef>;
  @ViewChildren("itemElementPlanned1To7") private itemElementPlanned1To7: QueryList<ElementRef>;
  lineList: any[] = [];
  dateArray: any[] = [];
  dlmDashboardData_lines: any[] = [];
  dlmDashboardData_1: any[] = [];
  dlmDashboardData_newKpiList: any[] = [];
  dlmDashboardData_2_topStop: any[] = [];
  dlmDashboardData_2_planToWin: any[] = [];
  dlmDashboardData_2_planned1to7: any[] = [];
  dlmDashboardData_UnscheduledDays: any[] = [];
  planned_move_id: any = [];
  planned_edit_id: any = [];
  planned_delete_id: any = [];
  planToWin_move_id: any = [];
  planToWin_edit_id: any = [];
  planToWin_delete_id: any = [];
  stopsValueZeroArray: any = [];
  lineName: any = '';
  lineID: any = '';
  date: any = '';
  maxDate: any = '';
  minDate: any = '';
  navigation: any = '';
  monthName: any = '';
  year: any = '';
  currentDateValue: any = '';
  currentDateValueData: any = '';
  currentDate: any = '';
  firstDay: any = '';
  lastDay: any = '';
  notes: any = '';
  line_name: any = '';
  line: any = '';
  dlmFullScreen: any = '';
  UnschStatus: any = '';
  ignoreFirstEvent: boolean = false;
  showNavigationArrows: boolean = false;
  showNavigationIndicators: boolean = false;
  selectedPlantName: any;
  PlantName: any;
  planListScrollThreshhold = 4;

  constructor(private netWorkSer: NetworkService, private toastr: ToastrService, private router: Router, private datePipe: DatePipe, private modalService: ModalService, private ref: ChangeDetectorRef, private loginService: LoginService, private confirmDialogService: ConfirmDialogService, config: NgbCarouselConfig, private loaderService: LoaderService, private route: ActivatedRoute, private translate: TranslateService, private languageService: LanguageService) {
    this.line_name = sessionStorage.getItem('line_name');
    this.selectedPlantName = sessionStorage.getItem('plant_name');
    const current_Date: any = this.datePipe.transform(new Date(),"MM/dd/yyyy", timeZone);
    const current = new Date(current_Date);
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.navigation = 'select';
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
  }

  @HostListener('window:resize', ['$event'])
  getPlantoWinScreenChange(event?:any) {
    this.getPlanToWinData();
  }

  @HostListener('window:resize', ['$event'])
  getPlanned1To7ScreenChange(event?:any) {
    this.getPlanned1to7Data();
  }

  ngOnInit(): void {
    if(this.languageService.getLanguage() == null)
      this.translate.use('en');
    else
      this.translate.use(this.languageService.getLanguage());

    this.currentDateValue = new Date();
    let dateValue: any = this.datePipe.transform(this.currentDateValue,"MM/dd/yyyy", timeZone)?.split('/');
    let dateValueData: any = dateValue[0]+'/'+dateValue[1]+'/'+dateValue[2];
    this.currentDateValueData = new Date(dateValueData);
    this.date = {year: Number(dateValue[2]), month: Number(dateValue[0]), day: Number(dateValue[1])};
    this.currentDate = this.datePipe.transform(this.currentDateValue,"yyyy-MM-dd", timeZone);
    console.log(this.currentDate);
    this.setDateEnable(this.currentDateValueData, 'current');
    this.loaderService.display(false);
    this.dlmFullScreen = setInterval(() => {
      this.getDLMDashboardData();
    }, 30000);
  }

  ngAfterViewChecked() :void {
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    if (this.dlmFullScreen) {
      clearInterval(this.dlmFullScreen);
    }
  }

  //select date
  setDateEnable(date: any, type: any) {
    if(type == 'change') {
      let newDate = ((date.month<10)?"0"+date.month:date.month)+"-"+date.day+"-"+date.year;
      let date1 = new Date(newDate);
      date = date1;
    }
    this.dateArray = [];
    var cdate: any = new Date(this.currentDate);
    if(this.languageService.getLanguageForDate().indexOf(null) > -1) {
      var month_Name: any = cdate.toLocaleString('default', { month: 'short' });
    } else {
      var month_Name: any = cdate.toLocaleString(this.languageService.getLanguageForDate(), { month: 'short' });
    }
    this.monthName = month_Name.charAt(0).toUpperCase() + month_Name.slice(1);
    this.year = cdate.getFullYear();
    if(type == 'next') {
      for(let i = 0; i < 7; i++) {
        let myFutureDate = new Date(date);
        myFutureDate.setDate(myFutureDate.getDate() + Number(i));
        if(i == 0) {
          this.firstDay = myFutureDate;
        }
        if(i == 6) {
          this.lastDay = myFutureDate;
          this.currentDate = this.datePipe.transform(myFutureDate,"yyyy-MM-dd");
        }
        let previousDate = myFutureDate.getDate();
        if(this.languageService.getLanguageForDate().indexOf(null) > -1) {
          var previousDateName = myFutureDate.toLocaleString('default', { weekday: 'short' });
        } else {
          var previousDateName = myFutureDate.toLocaleString(this.languageService.getLanguageForDate(), { weekday: 'short' });
        }
        this.dateArray.push({ previousDate: previousDate, previousDateName: previousDateName.charAt(0).toUpperCase() + previousDateName.slice(1), date: this.datePipe.transform(myFutureDate,"yyyy-MM-dd") });
      }
    } else {
      for(let i = 0; i < 7; i++) {
        let myPastDate = new Date(date);
        myPastDate.setDate(myPastDate.getDate() - Number(i));
        if(i == 0) {
          this.lastDay = myPastDate;
          this.currentDate = this.datePipe.transform(myPastDate,"yyyy-MM-dd");
        }
        if(i == 6) {
          this.firstDay = myPastDate;
        }
        let previousDate = myPastDate.getDate();
        if(this.languageService.getLanguageForDate().indexOf(null) > -1) {
          var previousDateName = myPastDate.toLocaleString('default', { weekday: 'short' });
        } else {
          var previousDateName = myPastDate.toLocaleString(this.languageService.getLanguageForDate(), { weekday: 'short' });
        }
        this.dateArray.push({ previousDate: previousDate, previousDateName: previousDateName.charAt(0).toUpperCase() + previousDateName.slice(1), date: this.datePipe.transform(myPastDate,"yyyy-MM-dd") });
      }
      this.dateArray.reverse();
    }
    this.getDLMDashboardData();
  }

  //get line details
  getLine() {
    if(this.currentDate!='') {
      this.route.params.mergeMap((params: Params) => {
        if(params['line']) {
          this.line = params['line'];
        }
        return Observable.of(false);
      }).subscribe((lineData: any) => {
        if (lineData) {
             this.line = lineData;
        }
      });
    }
  }

  //get DLM data
  getDLMDashboardData() {
    if(this.currentDate!='') {
      this.getLine();
      if(this.line!='') {
        var dlmDashboardData: any = {
          "Line": this.line,
          "CurrentDate": this.currentDate
        }
      } else {
        var dlmDashboardData: any = {
          "Line": localStorage.getItem('dlm_line_id')?.toString(),
          "CurrentDate": this.currentDate
        }
      }

      console.log(dlmDashboardData);
      
      this.netWorkSer.httpReqDLM('post', 'GetDLMDashboardTVDetails', dlmDashboardData).subscribe(response => {
        console.log(response.data);
        this.loaderService.display(false);
        this.dlmDashboardData_UnscheduledDays = [];
        this.dlmDashboardData_newKpiList = [];
        this.dlmDashboardData_1 = [];
        this.dlmDashboardData_2_topStop = [];
        this.dlmDashboardData_2_planToWin = [];
        this.dlmDashboardData_2_planned1to7 = [];
        // this.UnschStatus = '';
        if(response.data!='') {
          this.notes = response.data.Notes;
          // this.UnschStatus = response.data.UnschStatus;
          this.lineName = response.data.LineName;
          this.PlantName = response.data.PlantName;

          if(JSON.stringify(response.data.UnscheduledDays) != '[{}]' && JSON.stringify(response.data.UnscheduledDays) != undefined) {
            this.dlmDashboardData_UnscheduledDays = response.data.UnscheduledDays;
          }
          if(JSON.stringify(response.data.NEWKPIList) != '[{}]' && JSON.stringify(response.data.NEWKPIList) != undefined) {
            this.dlmDashboardData_newKpiList = response.data.NEWKPIList;
            if(this.dlmDashboardData_newKpiList.length > 0) {
              for(let x = 0; x < this.dlmDashboardData_newKpiList.length; x++) {
                this.dlmDashboardData_newKpiList[x].newKpiDateArray = [];
                if(this.dlmDashboardData_newKpiList[x].Condition == '=')
                  this.dlmDashboardData_newKpiList[x].Condition = '==';
                if(this.dlmDashboardData_newKpiList[x].NEWKPIListValues.length > 0) {
                  for(let y = 0; y < this.dlmDashboardData_newKpiList[x].NEWKPIListValues.length; y++) {
                    this.dlmDashboardData_newKpiList[x].newKpiDateArray.push(this.dlmDashboardData_newKpiList[x].NEWKPIListValues[y].CreatedDate);

                    //unschedule code
                    if(this.dlmDashboardData_UnscheduledDays.length > 0) {
                      if((this.dlmDashboardData_newKpiList[x].NEWKPIListValues[y].CreatedDate == this.dlmDashboardData_UnscheduledDays[y].CreatedDate) && this.dlmDashboardData_UnscheduledDays[y].Value === '3') {
                        this.dlmDashboardData_newKpiList[x].NEWKPIListValues[y].is_unschedule = true;
                      } else {
                        this.dlmDashboardData_newKpiList[x].NEWKPIListValues[y].is_unschedule = false;
                      }
                    }
                  }
                }

                if(this.dateArray.length > 0) {
                  for(let a = 0; a < this.dateArray.length; a++) {
                    if(this.dlmDashboardData_newKpiList[x].newKpiDateArray.indexOf(this.dateArray[a].date) !== -1) {
                    } else {
                      this.dlmDashboardData_newKpiList[x].NEWKPIListValues.push({
                        "CreatedDate": this.dateArray[a].date,
                        "Value": ""
                      });
                    }
                  }
                }
                this.dlmDashboardData_newKpiList[x].NEWKPIListValues.sort((x: any, y: any) => +new Date(x.CreatedDate) - +new Date(y.CreatedDate));
              }

              setTimeout(() => {
                for(let f = 0; f < this.dlmDashboardData_newKpiList.length; f++) {
                  let checkValue: any = this.isLetter(this.dlmDashboardData_newKpiList[f].Target);
                  if(this.dlmDashboardData_newKpiList[f].NEWKPIListValues.length > 0) {
                    for(let g = 0; g < this.dlmDashboardData_newKpiList[f].NEWKPIListValues.length; g++) {
                      if(!checkValue) {
                        // var conditionCheck: any = parseFloat(this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value) +' '+  this.dlmDashboardData_newKpiList[f].Condition +' '+ parseFloat(this.dlmDashboardData_newKpiList[f].Target);
                        var conditionValue: any = this.conditionCheck(parseFloat(this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value), this.dlmDashboardData_newKpiList[f].Condition, parseFloat(this.dlmDashboardData_newKpiList[f].Target));
                      } else {
                        // var conditionCheck: any =  "'"+this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value+"'" +' '+  this.dlmDashboardData_newKpiList[f].Condition +' '+ "'"+this.dlmDashboardData_newKpiList[f].Target+"'";
                        var conditionValue: any = this.conditionCheck("'"+this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value+"'", this.dlmDashboardData_newKpiList[f].Condition, "'"+this.dlmDashboardData_newKpiList[f].Target+"'");
                      }
                      if(this.UnschStatus !== 3) {
                        if(this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value!='' && this.dlmDashboardData_newKpiList[f].Target!='') {
                          if(conditionValue) {
                            this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].check = 'Y';
                          } else {
                            this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].check = 'N';
                          }
                          console.log(this.dlmDashboardData_newKpiList);
                        } else {
                          this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].Value = '';
                          this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].check = '';
                        }
                      } else {
                        this.dlmDashboardData_newKpiList[f].NEWKPIListValues[g].check = '';
                      }
                    }
                  }
                }
              }, 500);
            }
          }
          this.stopsValueZeroArray = [];
          if(JSON.stringify(response.data.KPIList) != '[{}]' && JSON.stringify(response.data.KPIList) != undefined) {
            this.stopsValueZeroArray = [];
            this.dlmDashboardData_1 = response.data.KPIList;
            //console.log(this.dlmDashboardData_1);
            if(this.dlmDashboardData_1.length > 0) {
              for(let i = 0; i < this.dlmDashboardData_1.length; i++) {
                this.dlmDashboardData_1[i].newDateArray = [];
                if(this.dlmDashboardData_1[i].Condition == '=')
                  this.dlmDashboardData_1[i].Condition = '==';
                if(this.dlmDashboardData_1[i].KPIListValues.length > 0) {
                  for(let j = 0; j < this.dlmDashboardData_1[i].KPIListValues.length; j++) {
                    this.dlmDashboardData_1[i].newDateArray.push(this.dlmDashboardData_1[i].KPIListValues[j].CreatedDate);


                    //unschedule code
                    if(this.dlmDashboardData_UnscheduledDays.length > 0) {
                      if((this.dlmDashboardData_1[i].KPIListValues[j].CreatedDate == this.dlmDashboardData_UnscheduledDays[j].CreatedDate) && this.dlmDashboardData_UnscheduledDays[j].Value === '3') {
                        if(this.dlmDashboardData_1[i].KPI === 'Safety' || this.dlmDashboardData_1[i].KPI === 'Food Safety/Quality' || this.dlmDashboardData_1[i].KPI === 'Defect Handling') {
                          this.dlmDashboardData_1[i].KPIListValues[j].Value = this.dlmDashboardData_1[i].KPIListValues[j].Value;
                          this.dlmDashboardData_1[i].KPIListValues[j].show_value = true;
                        } else {
                          this.dlmDashboardData_1[i].KPIListValues[j].Value = '';
                          this.dlmDashboardData_1[i].KPIListValues[j].show_value = false;
                        }
                        this.dlmDashboardData_1[i].KPIListValues[j].is_unschedule = true;
                      } else {
                        this.dlmDashboardData_1[i].KPIListValues[j].is_unschedule = false;
                      }
                    }
                  }
                }

                if(this.dateArray.length > 0) {
                  for(let j = 0; j < this.dateArray.length; j++) {
                    if(this.dlmDashboardData_1[i].newDateArray.indexOf(this.dateArray[j].date) !== -1) {
                    } else {
                      this.dlmDashboardData_1[i].KPIListValues.push({
                        "CreatedDate": this.dateArray[j].date,
                        "Value": ""
                      });
                    }
                  }
                }
                this.dlmDashboardData_1[i].KPIListValues.sort((x: any, y: any) => +new Date(x.CreatedDate) - +new Date(y.CreatedDate));
              }

              setTimeout(() => {
                this.stopsValueZeroArray = [];
                for(let f = 0; f < this.dlmDashboardData_1.length; f++) {
                  let checkValue: any = this.isLetter(this.dlmDashboardData_1[f].Target);
                  if(this.dlmDashboardData_1[f].KPIListValues.length > 0) {
                    // for(let g = 0; g < this.dlmDashboardData_1[f].KPIListValues.length; g++) {
                    //   if(!checkValue) {
                    //     var conditionValue: any = this.conditionCheck(parseFloat(this.dlmDashboardData_1[f].KPIListValues[g].Value), this.dlmDashboardData_1[f].Condition, parseFloat(this.dlmDashboardData_1[f].Target));
                    //   } else {
                    //     var conditionValue: any = this.conditionCheck("'"+this.dlmDashboardData_1[f].KPIListValues[g].Value+"'", this.dlmDashboardData_1[f].Condition, "'"+this.dlmDashboardData_1[f].Target+"'");
                    //   }
                    //   if(this.UnschStatus !== 3) {
                    //     if(this.dlmDashboardData_1[f].KPIListValues[g].Value!='' && this.dlmDashboardData_1[f].Target!='') {
                    //       if(conditionValue) {
                    //         this.dlmDashboardData_1[f].KPIListValues[g].check = 'Y';
                    //       } else {
                    //         this.dlmDashboardData_1[f].KPIListValues[g].check = 'N';
                    //       }

                    //       if(this.dlmDashboardData_1[f].KPIListValues[g].Value == 'MTBF') {
                    //         this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                    //       }
                    //     } else {
                    //       let currentDayStops: any = this.datePipe.transform(this.currentDateValueData,"yyyy-MM-dd")?.split('-')[2];
                    //         let createdDateStops: any = this.datePipe.transform(this.dlmDashboardData_1[f].KPIListValues[g].CreatedDate, "yyyy-mm-dd")?.split('-')[2];
                    //       if(this.dlmDashboardData_1[f].KPI == 'Stops') {
                    //         if(createdDateStops != currentDayStops)
                    //           this.dlmDashboardData_1[f].KPIListValues[g].Value = '0';
                    //         else
                    //           this.dlmDashboardData_1[f].KPIListValues[g].Value = '';
                    //         this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                    //         this.stopsValueZeroArray.push(this.dlmDashboardData_1[f].KPIListValues[g].Value);
                    //       } else if(this.dlmDashboardData_1[f].KPI == 'MTBF') {
                    //         if(this.stopsValueZeroArray.indexOf('0') > -1 && createdDateStops != currentDayStops) {
                    //           this.dlmDashboardData_1[f].KPIListValues[g].Value = 'MTBF';
                    //         } else {
                    //           this.dlmDashboardData_1[f].KPIListValues[g].Value = '';
                    //         }
                    //         this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                    //       }
                    //     }
                    //   } else {
                    //     this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                    //   }
                    // }
                    for(let g = 0; g < this.dlmDashboardData_1[f].KPIListValues.length; g++) {
                      if(!checkValue) {
                        var conditionValue: any = this.conditionCheck(parseFloat(this.dlmDashboardData_1[f].KPIListValues[g].Value), this.dlmDashboardData_1[f].Condition, parseFloat(this.dlmDashboardData_1[f].Target));
                      } else {
                        var conditionValue: any = this.conditionCheck("'"+this.dlmDashboardData_1[f].KPIListValues[g].Value+"'", this.dlmDashboardData_1[f].Condition, "'"+this.dlmDashboardData_1[f].Target+"'");
                      }
                      // if(this.dlmDashboardData_1[f].KPI === 'MTBF') {
                      //   this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                      // }
                      if(this.dlmDashboardData_1[f].KPIListValues[g].Value!='' && this.dlmDashboardData_1[f].Target!='') {
                        if(conditionValue) {
                          this.dlmDashboardData_1[f].KPIListValues[g].check = 'Y';
                        } else {
                          this.dlmDashboardData_1[f].KPIListValues[g].check = 'N';
                        }
                        if(this.dlmDashboardData_1[f].KPI === 'MTBF' && this.dlmDashboardData_1[f].KPIListValues[g].Value == 'MTBF') {  
                          this.dlmDashboardData_1[f].KPIListValues[g].check = 'Y';
                        }
                      } else {
                        let currentDayStops: any = this.datePipe.transform(this.currentDateValueData,"yyyy-MM-dd")?.split('-')[2];
                        let createdDateStops: any = this.datePipe.transform(this.dlmDashboardData_1[f].KPIListValues[g].CreatedDate, "yyyy-mm-dd")?.split('-')[2];
                        if(!this.dlmDashboardData_1[f].KPIListValues[g].is_unschedule) {
                          if(this.dlmDashboardData_1[f].KPI === 'Stops') {
                            if(createdDateStops != currentDayStops) {
                              this.dlmDashboardData_1[f].KPIListValues[g].Value = '0';
                              this.dlmDashboardData_1[f].KPIListValues[g].check = 'Y';
                            } else {
                              this.dlmDashboardData_1[f].KPIListValues[g].Value = '';
                              this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                            }
                            // this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                            this.stopsValueZeroArray.push(this.dlmDashboardData_1[f].KPIListValues[g].Value);
                          } else if(this.dlmDashboardData_1[f].KPI === 'MTBF') {
                            if(this.stopsValueZeroArray.indexOf('0') > -1 && createdDateStops != currentDayStops) {
                              this.dlmDashboardData_1[f].KPIListValues[g].Value = 'MTBF';
                              this.dlmDashboardData_1[f].KPIListValues[g].check = 'Y';
                            } else {
                              this.dlmDashboardData_1[f].KPIListValues[g].Value = '';
                              this.dlmDashboardData_1[f].KPIListValues[g].check = '';
                            }
                          }
                        } else {
                          this.dlmDashboardData_1[f].KPIListValues[g].Value = '';
                        }
                      }
                    }
                  }
                }
              }, 500);
            }
          }

          if(JSON.stringify(response.data.TopStops) != '[{}]' && JSON.stringify(response.data.TopStops) != undefined) {
            this.dlmDashboardData_2_topStop = response.data.TopStops;
            if(this.dlmDashboardData_2_topStop.length > 0) {
              for(let q = 0; q < this.dlmDashboardData_2_topStop.length; q++) {
                if(this.dlmDashboardData_2_topStop[q].SystemlinkageId==0) {
                  this.dlmDashboardData_2_topStop[q].SystemlinkageId = '';
                }
              }
            }
          }
          if(JSON.stringify(response.data.PlanToWinTheDay) != '[{}]' && JSON.stringify(response.data.PlanToWinTheDay) != undefined) {
            this.dlmDashboardData_2_planToWin = response.data.PlanToWinTheDay;
            if(this.dlmDashboardData_2_planToWin.length > 0) {
              for(let a = 0; a < this.dlmDashboardData_2_planToWin.length; a++) {
                this.dlmDashboardData_2_planToWin[a].planToWinCheck = false;
              }
              setTimeout(() => {
                this.autoScroll();
              }, 1000);
            }
          }
          if(JSON.stringify(response.data.Plan1To7Days) != '[{}]' && JSON.stringify(response.data.Plan1To7Days) != undefined) {
            this.dlmDashboardData_2_planned1to7 = response.data.Plan1To7Days;
            if(this.dlmDashboardData_2_planned1to7.length > 0) {
              for(let a = 0; a < this.dlmDashboardData_2_planned1to7.length; a++) {
                this.dlmDashboardData_2_planned1to7[a].plannedCheck = false;
                this.dlmDashboardData_2_planned1to7[a].newDate = this.datePipe.transform(this.dlmDashboardData_2_planned1to7[a].date,"MM/dd/yyyy");
                this.dlmDashboardData_2_planned1to7[a].date = this.datePipe.transform(this.dlmDashboardData_2_planned1to7[a].date,"MM/dd");
              }

              setTimeout(() => {
                this.autoScrollPlanned1To7();
              }, 1000);
            }
          }
        } else {
          if(response.error!='')
            this.toastr.error('', response.error, environment.toastOptions);
        }
      });
    }
  }

  //condition check
  conditionCheck(value: any, condition: any, target: any) {
    switch (condition) {
        case "==":
          if(value === target) {
            return true;
          } else {
            return false;
          }
          break;
        case ">":
          if(value > target) {
            return true;
          } else {
            return false;
          }
          break;
        case ">=":
          if(value >= target) {
            return true;
          } else {
            return false;
          }
          break;
        case "<":
          if(value < target) {
            return true;
          } else {
            return false;
          }
          break;
        case "<=":
          if(value <= target) {
            return true;
          } else {
            return false;
          }
          break;
        default:
          return false;
    }
  }

  //auto scroll in plan to win
  autoScroll() {
    // this.itemElementPlanToWin.changes.subscribe(() => {
      console.log("Item elements are now in the DOM!", this.itemElementPlanToWin.length);
      // console.log(this.itemElementPlanToWin['_results']);
      const minWidth = 10
      // console.log(this.itemElementPlanToWin['_results']);
      for(let ele = 0; ele < this.itemElementPlanToWin['_results'].length; ele++) {
        // console.log(this.itemElementPlanToWin['_results'][ele].nativeElement.offsetWidth + " == " + this.dlmDashboardData_2_planToWin[ele].details.length);

        // console.log("Difference = " + (this.itemElementPlanToWin['_results'][ele].nativeElement.offsetWidth - this.dlmDashboardData_2_planToWin[ele].details.length));
        // if((this.itemElementPlanToWin['_results'][ele].nativeElement.offsetWidth - this.dlmDashboardData_2_planToWin[ele].details.length) < 410) {
        //   this.dlmDashboardData_2_planToWin[ele].moveText = true;
        // } else {
        //   this.dlmDashboardData_2_planToWin[ele].moveText = false;
        // }
        var tempId: any = 'temp_id_'+ele;
        this.dlmDashboardData_2_planToWin[ele].moveText = false;
        var inputSelector: any = this.itemElementPlanToWin['_results'][ele].nativeElement.id;
        var text: any = this.dlmDashboardData_2_planToWin[ele].details;
        console.log("text = " + text);
        var inputWidth: any = $('#'+inputSelector).width();
        var spanWidth: any = $('#'+tempId).width();
        console.log("input width = " + inputWidth);
        console.log("span width = " + spanWidth);
        if(inputWidth < spanWidth) {
          this.dlmDashboardData_2_planToWin[ele].moveText = true;
        }
      }
    // });
  }

  //auto scroll in planned 1 to 7
  autoScrollPlanned1To7() {
    // this.itemElementPlanned1To7.changes.subscribe(() => {
      console.log("planned 1 to 7 = " + this.itemElementPlanned1To7.length);
      console.log("Item elements are now in the DOM!", this.itemElementPlanned1To7.length);
      const minWidth = 10
      for(let ele = 0; ele < this.itemElementPlanned1To7['_results'].length; ele++) {
        var tempId: any = 'temp_id_planned_'+ele;
        this.dlmDashboardData_2_planned1to7[ele].moveText = false;
        var inputSelector: any = this.itemElementPlanned1To7['_results'][ele].nativeElement.id;
        var text: any = this.dlmDashboardData_2_planned1to7[ele].details;
        console.log("text = " + text);
        var inputWidth: any = $('#'+inputSelector).width();
        var spanWidth: any = $('#'+tempId).width();
        console.log("input width = " + inputWidth);
        console.log("span width = " + spanWidth);
        if(inputWidth < spanWidth) {
          this.dlmDashboardData_2_planned1to7[ele].moveText = true;
        }
      }
    // });
  }

  //get plan to win
  getPlanToWinData() {
    if(this.currentDate!='') {
      this.getLine();
      if(this.line!='') {
        var dlmDashboardData: any = {
          "Line": this.line,
          "CurrentDate": this.currentDate
        }
      } else {
        var dlmDashboardData: any = {
          "Line": localStorage.getItem('dlm_line_id')?.toString(),
          "CurrentDate": this.currentDate
        }
      }
      this.netWorkSer.httpReqDLM('post', 'GetDLMDashboardTVDetails', dlmDashboardData).subscribe(response => {
        console.log(response.data);
        this.loaderService.display(false);
        this.dlmDashboardData_2_planToWin = [];
        if(response.data!='') {
          if(JSON.stringify(response.data.PlanToWinTheDay) != '[{}]' && JSON.stringify(response.data.PlanToWinTheDay) != undefined) {
            this.dlmDashboardData_2_planToWin = response.data.PlanToWinTheDay;
            if(this.dlmDashboardData_2_planToWin.length > 0) {
              for(let a = 0; a < this.dlmDashboardData_2_planToWin.length; a++) {
                this.dlmDashboardData_2_planToWin[a].planToWinCheck = false;
              }
              setTimeout(() => {
                this.autoScroll();
              }, 1000);
            }
          }
        } else {
          if(response.error!='')
            this.toastr.error('', response.error, environment.toastOptions);
          if(response.success!='')
            this.toastr.error('', response.success, environment.toastOptions);
        }
      });
    }
  }

  //get planned 1 to 7
  getPlanned1to7Data() {
    if(this.currentDate!='') {
      this.getLine();
      if(this.line!='') {
        var dlmDashboardData: any = {
          "Line": this.line,
          "CurrentDate": this.currentDate
        }
      } else {
        var dlmDashboardData: any = {
          "Line": localStorage.getItem('dlm_line_id')?.toString(),
          "CurrentDate": this.currentDate
        }
      }
      this.netWorkSer.httpReqDLM('post', 'GetDLMDashboardTVDetails', dlmDashboardData).subscribe(response => {
        console.log(response.data);
        this.loaderService.display(false);
        this.dlmDashboardData_2_planned1to7 = [];
        if(response.data!='') {
          if(JSON.stringify(response.data.Plan1To7Days) != '[{}]' && JSON.stringify(response.data.Plan1To7Days) != undefined) {
            this.dlmDashboardData_2_planned1to7 = response.data.Plan1To7Days;
            if(this.dlmDashboardData_2_planned1to7.length > 0) {
              for(let a = 0; a < this.dlmDashboardData_2_planned1to7.length; a++) {
                this.dlmDashboardData_2_planned1to7[a].plannedCheck = false;
                this.dlmDashboardData_2_planned1to7[a].newDate = this.datePipe.transform(this.dlmDashboardData_2_planned1to7[a].date,"MM/dd/yyyy");
                this.dlmDashboardData_2_planned1to7[a].date = this.datePipe.transform(this.dlmDashboardData_2_planned1to7[a].date,"MM/dd");
              }

              setTimeout(() => {
                this.autoScrollPlanned1To7();
              }, 1000);
            }
          }
        } else {
          if(response.error!='')
            this.toastr.error('', response.error, environment.toastOptions);
          if(response.success!='')
            this.toastr.error('', response.success, environment.toastOptions);
        }
      });
    }
  }

  //check string or number
  isLetter(str: any) {
    if(str.match(/[a-z]/i))
      return true;
    else
      return false;
    //return str.match(/[a-z]/i);
  }
}
