import { Injectable } from '@angular/core';
import { Subject, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data = {};
  events:any;
  listeners: any = {};
  constructor() {
    this.events = from(this.subject);
    this.events.subscribe((name:any, args:any) => {
      this.invokeListeners(name, args)
    });
  }

  private subject = new Subject<any>();

  invokeListeners(name: string | number, args: any) {
    if (this.listeners[name]) {
      for (const listnr of this.listeners[name]) {
        listnr(...args);
      }
    }
  }

  on(name: string, listener: (flag: Boolean, message: string) => void) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
  }

  broadcast(name: any, ...args: any[]) {
    console.log(args);
    this.subject.next({
      name,
      args
    });
  }
}
