import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { HashLocationStrategy, LocationStrategy, LOCATION_INITIALIZED } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient, HttpBackend } from '@angular/common/http';
import { MsalGuard, MsalModule} from '@azure/msal-angular';
import { MsalInterceptor } from '@azure/msal-angular';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { ADTokenInterceptor } from './core/services/adtoken-interceptor';
import { NetworkService } from './core/services/network.service';
import { ToastrModule } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from './error-handler.service';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { DlmManagementFullScreenComponent } from './dlm-management-full-screen/dlm-management-full-screen.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { MloChangeOverComponent } from './mlo-change-over/mlo-change-over.component';

// export function ApplicationInitializerFactory(
//   translate: TranslateService, injector: Injector) {
//   return async () => {
//     await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

//     const deaultLang = 'es';
//     translate.addLangs(['en', 'es']);
//     translate.setDefaultLang(deaultLang);
//     try {
//       await translate.use(deaultLang).toPromise();
//     } catch (err) {
//       console.log(err);
//     }
//     console.log(`Successfully initialized ${deaultLang} language.`);
//   };
// }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(http), './assets/i18n/', '.json');
}

// export const protectedResourceMap: [string, string[]][] = [['https://graph.microsoft.com/v1.0/me', ['user.read', 'user.read.all']]];
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    DlmManagementFullScreenComponent,
    HealthCheckComponent,
    MloChangeOverComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    Ng2ImgMaxModule,
    ToastrModule.forRoot(),
    NgbModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.msalAuthConfig.auth.clientId, // This is your client ID
        //clientId: 'd4cb7957-8f61-426c-9c2c-7b16cbf972fc', // This is your client ID
        authority: environment.msalAuthConfig.auth.authority,// This is your tenant info
        redirectUri: environment.msalAuthConfig.auth.redirectUri,// This is your redirect URI,
        postLogoutRedirectUri: environment.msalAuthConfig.auth.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    },
    {
      popUp: !isIE,
      consentScopes: ["user.read", "openid", "profile"],
      unprotectedResources: ['https://www.microsoft.com/en-us/'],
      protectedResourceMap: [['./assets/i18n/*.json', null as any]],
      extraQueryParameters: {}
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
      },
      isolate: false,
      defaultLanguage: 'en'
    }),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    FeaturesModule,
    NgxMaterialTimepickerModule
  ],
  exports: [TranslateModule],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ADTokenInterceptor,
      multi: true
    },
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    NetworkService,
    // TranslateService,
    NgbModal,
    MsalGuard,
    BnNgIdleService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: ApplicationInitializerFactory,
    //   deps: [TranslateService, Injector],
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }