import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap, finalize } from 'rxjs/operators';
// import { Response } from '../../../node_modules/@angular/http';
import { environment } from '../../../environments/environment';
// import { SpinnerService } from '../../shared/services/spinner.service';
import { MsalService } from '@azure/msal-angular';
import { AdalService } from './adal.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
// import { AdalService } from './adal.service';

class DataTablesResponse {
  data!: any[];
  draw!: number;
  recordsFiltered!: number;
  recordsTotal!: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
@Injectable()
export class NetworkService {
  languages: any = [
    { 'languageCode': 'en', 'languageName': 'English' },
    { 'languageCode': 'es', 'languageName': 'Spanish' }
  ]
  constructor(private http: HttpClient,private loaderSer: LoaderService, private translate: TranslateService, private languageService: LanguageService) { }

  httpReq(reqType: any, endURL: any, additionalParam?: any, data?: any, isFile = false, isBlob = false): Observable<any> {
    // const fullURL = 'https://reqres.in/api/' + endURL;
    let fullURL = environment.serverUrl + endURL + "?code="+environment.hostKey;
    if (additionalParam) {
      fullURL = fullURL + "&" + additionalParam;
    }
    this.loaderSer.display(true);
    switch (reqType) {
      case 'get':
        const encodedURL = this.encodeURL(fullURL, data);
        return this.http.get(encodedURL, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', [])));

      case 'post':
        return this.http.post(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'put':
        return this.http.put(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );
      case 'postDatatable':
        return this.http.post<DataTablesResponse>(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'delete':
        const encodedURLs = this.encodeURL(fullURL, data);
        return this.http.delete(encodedURLs, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      default: return EMPTY;
    }
  }

  httpReqDLM(reqType: any, endURL: any, data?: any, isFile = false, isBlob = false): Observable<any> {
    const fullURL = environment.serverUrl + endURL + "?code=" + environment.hostKey;
    //this.loaderSer.display(true);
    switch (reqType) {
      case 'get':
        const encodedURL = this.encodeURL(fullURL, data);
        return this.http.get(encodedURL, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', [])));

      case 'post':
        return this.http.post(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'put':
        return this.http.put(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );
      case 'postDatatable':
        return this.http.post<DataTablesResponse>(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'delete':
        const encodedURLs = this.encodeURL(fullURL, data);
        return this.http.delete(encodedURLs, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      default: return EMPTY;
    }
  }

  handleHTTPSuccess(result: any, isBlob = false) {

    this.loaderSer.display(false);
    if (result.status === 401) {
    }
    return result;
  }

  handleError<T>(operation = 'operation', result?: T) {
    // this.spinnerSer.toggleSpinner(0);
    return (error: any): Observable<T> => {
      let errMsg = '';
      if (error && error.error) {
        errMsg = (error.error.errorMessage || error.error.message);
      }
      console.error("handleError",error);
      
      this.showError(errMsg);
      return of(result as T);
    };
  }

  showError(errorMsg: string) {
    // this.spinnerSer.toggleSpinner(0);
    // this.toastr.error(errorMsg, Error);
  }

  setHeaders(isFile: boolean) {
    if (!isFile) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + localStorage.getItem('msal.idtoken')
        })
      };
    }
    return {
      headers: new HttpHeaders({
        Accept: "application/json",
        // 'Authorization': 'Bearer ' + localStorage.getItem('msal.idtoken')
      })
    };
  }

  encodeURL(url: any, data: { [x: string]: string | number | boolean; }) {
    let encodedUrl = url;
    if (data) {
      encodedUrl =
        encodedUrl +
        "?" +
        Object.keys(data)
          .map(function (k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
          })
          .join("&");
    }
    return encodedUrl;
  }

  checkFileExists(file: any) {
    if(file == "" || file == null){
      return false;
    }
    var request = new XMLHttpRequest();
    request.open('HEAD', file, false);
    request.send();
    if(request.status == 200) {
      return true;
    } else {
      return false;
    }
  }

  translateLanguage() {
    if(this.languages.length > 0) {
      for(let j = 0; j < this.languages.length; j++) {
        if(this.languageService.getLanguage() == this.languages[j].languageCode) {
          return this.languages[j].languageName;
        }
      }
    }
  }

  public translateText(key: string | string[], interpolateParams?: any): string {
    console.log(interpolateParams);
    return this.translate.instant(key, interpolateParams);
  }
}
