import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject } from 'rxjs-compat';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { NetworkService } from 'src/app/core/services/network.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { environment } from 'src/environments/environment';

export class User {
  isUserLoggedIn: boolean;
  error: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private dataStringSource: BehaviorSubject<User>;
  public dataString$: Observable<User>;

  loggedIn = false;
  isUserLoggedIn: boolean = false;
  loggedInUserData: any;
  userName: any;
  userRole: any;
  error: any = '';
  data: any = {};

  constructor(private http: HttpClient, private authService: MsalService, private loaderService: LoaderService, private netWorkSer: NetworkService) {
    this.dataStringSource = new BehaviorSubject<User>({ isUserLoggedIn: false, error: '' });
    this.dataString$ = this.dataStringSource.asObservable();
    // this.getGraphApiToken();
  }

  checkLogin() {
    this.loggedIn = !!this.authService.getAccount();
    return this.loggedIn;
  }

  // checkUserLogin() {
  //   alert(localStorage.getItem('office_location'));
  //   this.loaderService.display(true);
  //   if(localStorage.getItem('office_location')!='null' || localStorage.getItem('office_location')!='') {
  //     let offcLocation: any = localStorage.getItem('office_location')?.split(' ');
  //     var url = 'ClaimToken/'+offcLocation[0];
  //     this.netWorkSer.httpReq('get', url).subscribe(response => {
  //       if(response.length > 0) {
  //         sessionStorage.setItem('loggedInUserData', JSON.stringify(this.loggedInUserData));
  //         if(response[0].isAuthorized == true && response[0].isActive == true && response[0].roleName != '') {
  //           this.isUserLoggedIn = true;
  //           this.error = '';
  //           this.data = { 'isUserLoggedIn': this.isUserLoggedIn, 'error': this.error };
  //           this.dataStringSource.next(this.data);
  //           setTimeout(() => {
  //             this.loaderService.display(false);
  //           }, 3000);
  //         } else if(response[0].isAuthorized == true && response[0].isActive == false && response[0].roleName != '') {
  //           this.isUserLoggedIn = false;
  //           //User authorized & not active in TPM Mobile
  //           this.loaderService.display(true);
  //           this.error = 'User not Enabled. Please contact TPM Admin';
  //           this.data = { 'isUserLoggedIn': this.isUserLoggedIn, 'error': this.error };
  //           this.dataStringSource.next(this.data);
  //         } else if(response[0].isAuthorized == true && response[0].isActive == false && response[0].roleName == '') {
  //           this.isUserLoggedIn = false;
  //           //User not present in TPM Mobile
  //           this.loaderService.display(true);
  //           this.error = 'Please contact TPM Admin for TPM Mobile Access';
  //           this.data = { 'isUserLoggedIn': this.isUserLoggedIn, 'error': this.error };
  //           this.dataStringSource.next(this.data);
  //         } else {
  //           this.isUserLoggedIn = false;
  //           //This appears when id_token is not valid
  //           this.loaderService.display(true);
  //           this.error = 'Session Timeout. Please re-login';
  //           this.data = { 'isUserLoggedIn': this.isUserLoggedIn, 'error': this.error };

  //         }
  //       }
  //     });
  //     return this.data;
  //   } else {
  //     this.isUserLoggedIn = false;
  //     //User not present in TPM Mobile
  //     this.loaderService.display(true);
  //     this.error = 'Please contact TPM Admin for TPM Mobile Access';
  //     this.logout();
  //   }
  // }

  getUserName() {
    let user: any = this.authService.getAccount();
    return user.userName.split('@')[0];
  }

  getUserEmail() {
    let user: any = this.authService.getAccount();
    return user.userName;
  }

  getUserAzureName() {
    let user: any = this.authService.getAccount();
    return user.name.split(',')[1];
  }

  getUserFullName() {
    let user: any = this.authService.getAccount();
    if(user.name.split(',')[0]!=undefined && user.name.split(',')[1]!=undefined)
      return user.name.split(',')[1]+' '+user.name.split(',')[0];
    else
      return user.name;
  }

  login() {
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logout();
  }

  getGraphApiToken(): Observable<any> {
    localStorage.setItem('graphApiToken', '');
    return this.netWorkSer.httpReq('get', 'GetAzureGraphToken');
  }

  getOffcLocation(): Observable<any> {
    localStorage.setItem('office_location', '');
    // const API: any = 'https://graph.microsoft.com/v1.0/users/lmuacxb@richproductscorporation.onmicrosoft.com';
    // const API: any = 'https://graph.microsoft.com/v1.0/users/lmuacxb@rich.com';
    const API: any = 'https://graph.microsoft.com/v1.0/users/'+this.getUserEmail();
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + localStorage.getItem("graphApiToken"),
      })
    };
    return this.http.get(API, httpOptions);
  }

  // logoutUser() {
  //   setTimeout(() => {
  //     this.logout();
  //   }, environment.errorMessageTimeout);
  // }
}
